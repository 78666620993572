export class LoggedUserModel {
  senha: number;
  idUsuario: number;
  idEmpresa: number;
  codigo: string;
  trocaSenha: number;
  nomeEmpresa: string;
  nomeUsuario: string;
  idCliente: number;
  idDbEmpresa: number;
  id_plantaPadrao:number;
  token: string;
  urlArquivo:string;
  id_permissaoMaster:number;
}
