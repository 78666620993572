import { version } from '../../package.json';

// const URL = 'https://woserviceapi.brazilsouth.cloudapp.azure.com/ic4u/api/'; //API Remota Prod
const URL = 'https://ic4uapi.azurewebsites.net/ic4u/api/'; //API Remota Prod Docker
// const URL = 'http://localhost:3000/ic4u/api/';

// const WS_URL = 'https://woserviceapi.brazilsouth.cloudapp.azure.com/'; //API VM
const WS_URL = 'https://ic4uapi.azurewebsites.net/'; //API Remota Prod Docker
// const WS_URL = 'http://localhost:3000';

export function getWSUrl() {
  return WS_URL;
}
export function getUrl() {
  return URL;
}

export const environment = {
  version: "d-" + version,
  empresa: "1729",
  id_empresa: "908",
  // empresa: "9034",
  // id_empresa: "902",
  pedirCodEmpresa: false,
  production: false,
  cookie: false,
  getUrl,
  getWSUrl,
  API_ENDPOINT: {
    'apiMenu': URL + 'menu',
    'apiLogin': URL + 'login',
    'apiPlantas': URL + 'plantas',
    'apiNavios': URL + 'navios',
    'apiTanques': URL + 'tanques',
    'apiBercos': URL + 'bercos',
    'apiPraticos': URL + 'praticos',
    'apiRebocadores': URL + 'rebocadores',
    'apiMandatarios': URL + 'mandatarios',
    'apiCompradores': URL + 'compradores',
    'apiDac': URL + 'dac',
    'apiSaida': URL + 'saida',
    'apiRgistroNf': URL + 'info-rfb',
    'apiSendNf': URL + 'send-nf',
    'apiCertificados': URL + 'certificados',
    'apiTerminais': URL + 'terminais',
    'apiPontosDeControle': URL + 'pontos-controle',
    'apiOperacoesS2S': URL + 'operacoes/s2s',
    'apiOperacoesS2T': URL + 'operacoes/s2t',
    'apiOperacoesEvento': URL + 'operacoes-eventos',
    'apiOperacoesVazao': URL + 'operacoes-vazao',
    'apiUsuarios': URL + 'usuarios',
    'apiPerfilDeAcesso': URL + 'perfis',
    'apiRestricoesDeAcesso': URL + 'retricoes',
    'apiCadastro': URL + 'cadastro',
    'apiArquivoOperacao': URL + 'arquivos-operacao',
    'apiArquivos': URL + 'arquivos',
    'apiMedicoes': URL + 'operacao-medicao',
    'apiClientes': URL + 'cliente',
    'apiRelatorios': URL + 'relatorios',
    'apiOperacoesRelatorio': URL + 'relatorios/operacoes',
    'apiCheckPerfil': URL + 'checkPerfil',
    'apiLogout': URL + 'logout',
    'apiDrive': URL + 'drive',
    'apiKpis': URL + 'kpis',
    'apiKpisPortoSudeste': URL + 'porto-sudeste/kpis',
    'apiParadas': URL + 'paradas',
    'apiTipoParadas': URL + 'paradas/tipo',
    'apiGraficos': URL + 'graficos',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.