import { AuthenticationService } from '../authentication/authentication.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class KpiService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService
  ) { }

  // async getOperacoesCliente(): Promise<any> {

  //   return new Promise((resolve, reject) => {
  //     this.httpClient.get(environment.API_ENDPOINT.apiKpis + "/operacoes-cliente")
  //       .toPromise()
  //       .then(res => resolve(res))
  //       .catch(err => reject(err));
  //   });
  // }

  async getTempoOperacional(mesano): Promise<any> {

    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.API_ENDPOINT.apiKpis + "/tempo-operacional", {
        params: {
          mesano: mesano
        }
      })
        .toPromise()
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  }

  async getEtapasOperacionais(id_operacao: number = 0): Promise<any> {

    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.API_ENDPOINT.apiKpis + "/etapas-operacionais", {
        params: {
          id_operacao: id_operacao.toString(),
        }
      })
        .toPromise()
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  }

  async getKpiEtapasOperacionais(id_operacao: number = 0): Promise<any> {

    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.API_ENDPOINT.apiKpisPortoSudeste + "/kpi-etapas", {
        params: {
          id_operacao: id_operacao.toString()          
        }
      })
        .toPromise()
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  }

  async getProcessamentoLote(id_operacao: number = 0): Promise<any> {

    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.API_ENDPOINT.apiKpis + "/processamento-lote", {
        params: {
          id_operacao: id_operacao.toString(),
        }
      })
        .toPromise()
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  }

  async getAllConcluidasKpis(): Promise<any> {

    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.API_ENDPOINT.apiKpis + '/concluidas-kpis')
        .toPromise()
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  }
}
