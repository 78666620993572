import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, Inject, OnInit } from '@angular/core';
import * as fileSaver from 'file-saver';
// import { base64toBlob } from 'src/app/services/util/util.service';

@Component({
  selector: 'app-file-upload-template-component',
  templateUrl: 'file-upload-template.component.html',
  styleUrls: ['./file-upload-template.component.scss']
})

export class FileUploadTemplateComponent implements OnInit {
  iconType: string;
  iconPosition: string;
  title: string;
  text: string;
  options: false;
  input: false;
  dataType: string;
  button: string;
  size: number = 0;
  maxSize: number = 1000000;
  tiposArquivo: any;
  arquivosExistentes: any;
  uploaders = {
    avatar: {
      progress: undefined,
      url: undefined
    },
    image: {
      progress: undefined,
      url: undefined
    },
    files: {
      list: [],
      invalidList: []
    }
  };
  public iab: any
  public alert:any

  constructor(
    public dialogRef: MatDialogRef<FileUploadTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public snackBar: MatSnackBar,
  ) {
    this.iconType = data.iconType;
    this.iconPosition = data.iconPosition ? data.iconPosition : 'default';
    this.title = data.title;
    this.text = data.text;
    this.options = data.options;
    this.input = data.input;
    this.button = data.button;
    this.dataType = data.dataType;
    this.tiposArquivo = data.tiposArquivo;
    this.arquivosExistentes = data.arquivosExistentes;
    this.alert = data.alert
    this.iab = data.iab
    console.log(this.arquivosExistentes)
    if (data.time) {
      setTimeout(() => {
        this.dialogRef.close();
      }, data.time);
    }
  }

  ngOnInit() {
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === "Escape") {
        this.dialogRef.close();
      }
    });

    this.dialogRef.backdropClick().subscribe(event => {
      this.dialogRef.close();
    });
  }

  onMultipleChange(event: any, uploader: string): void {
    console.log(event.target.files)
    this.onDropzoneMultipleChange(event.target.files, uploader);
  }

  onSingleChange(event: any, uploader: string): void {
    this.onDropzoneSingleChange(event.target.files, uploader);
  }

  onDropzoneMultipleChange(fileList: Array<File>, uploader: string): void {
    console.log('aqui')
    for (const file of fileList) {
      this.size = this.size + file.size
      console.log(this.size)
      const l = this.uploaders[uploader].list.push(file);
      this.read(file, this.uploaders[uploader].list[l - 1]);
    }
  }

  checkFiles() {
    return this.uploaders.files.list.map(el => el.id_tipoArquivo).some(el => !el)
  }

  onInvalidFiles(files: Array<File>): void {
    // this files are invalid because their extension is not allowed on this control
    console.log("The files are not supported by this control")
    // this.snackBar.openFromComponent(NotificationTemplateComponent, {
    //   data: {
    //     message: 'The files are not supported by this control',
    //     icon: 'exclamation-triangle',
    //     type: 'danger',
    //     dismissible: true
    //   },
    //   duration: 3000,
    //   horizontalPosition: 'start',
    //   verticalPosition: 'top',
    //   panelClass: ['notification-wrapper']
    // });
  }

  onDropzoneSingleChange(files: Array<File>, uploader: string): void {
    this.uploaders[uploader] = files[0];
    this.read(files[0], this.uploaders[uploader]);
    console.log(this.uploaders.files.list)

  }

  resetUploader(uploader: string): void {
    if (uploader === 'files') {
      this.uploaders[uploader] = {
        list: [],
        invalidList: []
      };
    } else {
      this.uploaders[uploader] = {};
    }
  }

  // post(): void {
  //   this.snackBar.openFromComponent(NotificationTemplateComponent, {
  //     data: {
  //       message: 'Your file has been uploaded successfully',
  //       icon: 'check-circle',
  //       type: 'success',
  //       dismissible: true
  //     },
  //     duration: 3000,
  //     horizontalPosition: 'start',
  //     verticalPosition: 'top',
  //     panelClass: ['notification-wrapper']
  //   });

  //   setTimeout(() => {
  //     this.resetUploader('image');
  //   }, 600);
  // }

  read(file: File, store: any): void {
    store.total = (file.size / 1024).toFixed(2);
    store.progress = 0;
    store.loaded = 0;
    const reader = new FileReader();
    console.log(store.url)
    reader.onload = (e: any) => {
      store.url = e.target.result;
    };

    reader.onprogress = (e: ProgressEvent) => {
      if (e.lengthComputable) {
        store.progress = Math.round((e.loaded / e.total) * 100);
        store.loaded = (e.loaded / 1024).toFixed(2);
      }
    };

    reader.readAsDataURL(file);
    console.log(reader)
  }

  allowDrop(event) {
    console.log(event)
  }
  fileDelete(file) {
    this.uploaders.files.list.splice(this.uploaders.files.list.indexOf(file), 1)
    this.size = this.size - file.size
  }
  click(id) {
    console.log(id)
  }
  async downloadArquivo(file) {
    // console.log(file)
    this.data.operacoesService.downloadArquivo(file.urlArquivo)
      .then(async(res: any) => {
        // console.log(res)
        await this.iab.create(res, '_blank')
      })
      .catch((err) => {
        this.alert.overflowIconErrorAlert({
          title: "ALERT.ERRO",
          text: "Algo deu errado e sua operação não foi concluída. \n" + err.message
        });
      })

    // this.data.operacoesService.downloadArquivo(file.urlArquivo)
    //   .then((res: any) => {
    //     console.log(res)
    //     // fileSaver.saveAs(
    //     //   this.base64toBlob(res.file, file.mimeType),
    //     //   res.fileName
    //     // );
    //   })
    //   .catch(err => {
    //     console.log(err)
    //   })
  }

  deleteFileExistente(file) {
    console.log(file.id_arquivo)
    this.alert.optionsAlert({
      title: 'ALERT.ATENCAO',
      message: 'Você deseja excluir esse arquivo ?',
      okFunction: (data) => {
        this.data.operacoesService.deleteFile(file.id_arquivo,file.urlArquivo)
          .then((res: any) => {
            console.log(res)
            this.dialogRef.close();
          })
          .catch(err => {
            console.log(err)
          })
      }
    });

  }

  base64toBlob(b64Data, contentType) {
    contentType = contentType || '';
    const sliceSize = 512;
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    try {
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);

      }

      // const blob = new Blob(byteArrays);
      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    }
    catch (err) {
      return err;
    }
  }
}
