import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-calado-saida',
  templateUrl: './calado-saida.component.html',
  styleUrls: ['./calado-saida.component.scss'],
})
export class CaladoSaidaComponent implements OnInit {
 
  public title: string;
  public caladoSaida: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CaladoSaidaComponent>
  ) {
    this.title = data.title;
    this.caladoSaida = data.caladoSaidaValue? data.caladoSaidaValue:0;//? parseFloat(data.caladoSaidaValue.replace(",", ".")) :0;
  }

  ngOnInit() { 
    const d = new Date()
  }

  registrarDataFimEvento() {
    //console.log(this.horaFim)
    this.dialogRef.close({ caladoSaida: this.caladoSaida });
  }

  closeFunction(){
    this.dialogRef.close({ canceled: true });
  }

}
