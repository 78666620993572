import { Component, OnInit } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { UtilService } from 'src/app/services/util/util.service';

@Component({
  selector: 'app-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.scss']
})
export class ClockComponent implements OnInit {

  public date = new Date();

  constructor(
    public usuarioService: UsuarioService,
    public utilService: UtilService,
    public socket: Socket,
  ){
    setInterval(() => {
      this.date = new Date();
    }, 1000)
  }
  ngOnInit() {
  }

}
