import { CustomLoaderService } from 'src/app/services/custom-loader/custom-loader.service';
import { CustomAlertService } from 'src/app/services/custom-alert/custom-alert.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Login } from 'src/app/services/authentication/Login';
import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sign-in-modal',
  templateUrl: './sign-in-modal.component.html',
  styleUrls: ['./sign-in-modal.component.scss']
})

export class SignInModalComponent implements OnInit {

  public modalForm: FormGroup;
  public login: Login = new Login();
  public environment = environment;

  constructor(
    public dialogRef: MatDialogRef<SignInModalComponent>,
    public dialogRef2: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private customLoader: CustomLoaderService,
    public customAlert: CustomAlertService,
    public router: Router,

  ) {
    // console.log(data)
  }

  async ngOnInit() {
    this.modalForm = new FormGroup({
      password: new FormControl(''),
      email: new FormControl(''),
      cod_empresa: new FormControl(''),
      rememberMe: new FormControl(false),
    });
    if (await this.data.service.temUsuarioSalvo()) {
      this.data.service.temUsuarioSalvo().then((usuarioSalvo) => {
        this.modalForm.setValue({
          cod_empresa: environment.pedirCodEmpresa ? "" : environment.empresa,
          email: usuarioSalvo['login'],
          password: usuarioSalvo['senha'],
          rememberMe: true
        });
        this.signIn();
      })
    }
  }

  async signIn() {
    // this.login.codigo = await this.utils.getIdEMpresa();
    this.login.codigo = environment.pedirCodEmpresa ? this.modalForm.value.cod_empresa : environment.empresa;
    this.login.login = this.modalForm.value.email;
    this.login.senha = this.modalForm.value.password;
    if (this.login.codigo) {

      await this.data.service.signin(this.login, this.modalForm.value.rememberMe)
        .then((response: any) => {

          if (this.data.returnUser) {
            this.dialogRef.close(response);
            setTimeout(() => {
              this.dialogRef2.closeAll();
            }, 200);
            this.customLoader.dismiss();

          }
          else {
            if (this.data.router)
              this.data.router.navigate(['operacao/operacoes']);
            this.dialogRef.close(true);
            this.customLoader.dismiss();
          }

        }).catch((err: any) => {

          this.customLoader.dismiss();
          console.log(err)
          if (err.status == 0) {
            this.customAlert.overflowIconErrorAlert({
              title: "ALERT.ATENCAO",
              text: "Não foi possível se conectar com o servidor,por favor reveja a conexão"
            });
          }
          if (err.senha == 0) {
            this.customAlert.overflowIconErrorAlert({
              title: "ALERT.ATENCAO",
              text: "Usuário ou senha incorretos!"
            });
          }
          else {
            this.customAlert.overflowIconErrorAlert({
              title: "ALERT.ATENCAO",
              text: err.error.message
            });
          }

        });

    }
    else if (!this.login.login) {
      this.data.router.navigate(['']);
    }
    // else {
    //   console.log("Esqueci a Senha: ", this.login.login);
    // }

  }

  cancel(): void {
    this.dialogRef.close();
  }

}
