import { AuthenticationService } from '../../authentication/authentication.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class PerfilDeAcessoService {

  public permissoes: any[] = []

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
  ) {
    this.getPermissoes().then(res => this.permissoes = res)
  }

  async getPermissoes(): Promise<any> {
    const user: any = await this.authService.getUserByLogin(this.authService);
    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.API_ENDPOINT.apiMenu + "/opcoes", {
        // params: { "id_empresa": user.idEmpresa.toString() }
      })
        .toPromise()
        .then(res => resolve(res))
        .catch(err => reject(err));
    })
  }

  async getPerfisDeAcesso(): Promise<any> {
    const user: any = await this.authService.getUserByLogin(this.authService);
    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.API_ENDPOINT.apiPerfilDeAcesso, {
        params: { "id_empresa": user.idEmpresa.toString() }
      })
        .toPromise()
        .then(res => resolve(res))
        .catch(err => reject(err));
    })
  }

  async addPerfilDeAcesso(perfilDeAcesso: PerfilDeAcesso): Promise<any> {
    const user: any = await this.authService.getUserByLogin(this.authService);
    perfilDeAcesso.id_usuario = user.idUsuario;
    perfilDeAcesso.id_empresa = user.idEmpresa;
    console.log(perfilDeAcesso);

    return new Promise((resolve, reject) => {
      this.httpClient.post(environment.API_ENDPOINT.apiPerfilDeAcesso, perfilDeAcesso)
        .toPromise()
        .then(res => resolve(res))
        .catch(err => reject(err));
    })
  }

  async editPerfilDeAcesso(perfilDeAcesso: PerfilDeAcesso): Promise<any> {
    const user: any = await this.authService.getUserByLogin(this.authService);
    perfilDeAcesso.id_usuario = user.idUsuario;
    perfilDeAcesso.id_empresa = user.idEmpresa;
    console.log(perfilDeAcesso);

    return new Promise((resolve, reject) => {
      this.httpClient.put(environment.API_ENDPOINT.apiPerfilDeAcesso, perfilDeAcesso)
        .toPromise()
        .then(res => resolve(res))
        .catch(err => reject(err));
    })
  }

  async delPerfilDeAcesso(perfilDeAcesso: PerfilDeAcesso): Promise<any> {
    const user: any = await this.authService.getUserByLogin(this.authService);
    perfilDeAcesso.id_usuario = user.idUsuario;
    perfilDeAcesso.id_empresa = user.idEmpresa;
    console.log(perfilDeAcesso);

    return new Promise((resolve, reject) => {
      this.httpClient.delete(environment.API_ENDPOINT.apiPerfilDeAcesso, {
        params: {
          "id_empresa": user.idEmpresa.toString(),
          "id_usuario": user.idUsuario.toString(),
          "id_perfil": perfilDeAcesso.id_perfil.toString(),
        }
      })
        .toPromise()
        .then(res => resolve(res))
        .catch(err => reject(err));
    })
  }

  async checkPerfil(data: any): Promise<any> {
    const user: any = await this.authService.getUserByLogin(this.authService);
    let body = {
      id_menu: data
    }

    return new Promise((resolve, reject) => {
      this.httpClient.post(environment.API_ENDPOINT.apiCheckPerfil, body)
        .toPromise()
        .then(res => resolve(res))
        .catch(err => reject(err));
    })
  }


}

@Injectable()
export class PerfilDeAcesso {
  id_empresa?: number = 0;
  id_usuario?: number = 0;
  id_perfil: number = 0;
  id_restricoes: number = 0;
  descricao: string = "";
  listaMenuCKP?: number[]
}

@Injectable()
export class Permissao {
  id_permissao: number = 0;
  permissao: string = "";
}

