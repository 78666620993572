import { AuthenticationService } from '../authentication/authentication.service';
import { UsuarioService, UsuarioDAO } from './../usuario/usuario.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})

export class UtilService {

  public reLogin: boolean = false;

  constructor(

    private httpClient: HttpClient,
    public userService: UsuarioService,
    private authService: AuthenticationService,
    private translateService: TranslateService,
  ) { }

  public getUsuario(): UsuarioDAO {
    if (this.userService.getEmpresaId() > 0) {
      return this.userService.getUsuario();
    }
    else {
      if (!this.reLogin) {
        this.authService.openSignInModal(null, null, null, this.authService)
        this.reLogin = true;
      }
      return new UsuarioDAO();
    }
  }

  async getMenu() {
    return new Promise((resolve, reject) => {

      this.httpClient.get(environment.API_ENDPOINT.apiMenu, {
        params: {
          id_usuario: this.userService.getUsuarioId().toString(),
          id_empresa: this.userService.getEmpresaId().toString(),
          linguagem: this.translateService.currentLang,
        }
      })
        .toPromise()
        .then(async (response: any) => {
          resolve(response);
        }).catch(error => {
          console.log(error);
          reject(error);
        });
    });
  }

  async getTiposArquivo() {

    return new Promise((resolve, reject) => {

      this.httpClient.get(environment.API_ENDPOINT.apiArquivos + "/tipos", {
        params: {
          // id_usuario: this.userService.getUsuarioId().toString(),
          id_empresa: this.userService.getEmpresaId().toString()
        }
      })
        .toPromise()
        .then(async (response: any) => {
          resolve(response);
        }).catch(error => {
          console.log(error);
          reject(error)
        });
    });
  }

  buscaLogo(file_path) {
    if (file_path) {
      return new Promise(async (resolve, reject) => {
        this.httpClient.get(environment.API_ENDPOINT.apiDrive + "/link-url", {
          params: {
            file_url: file_path
          }
        })
          .toPromise()
          .then((response: any) => {
            resolve(response.url[0])
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  }

  buscaLogoBase64() {
    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.API_ENDPOINT.apiCadastro + "/logoBase64", {
        params: {
          id_empresa: this.userService.getEmpresaId().toString()
        }
      })
        .toPromise()
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  }

  base64toBlob(b64Data, contentType, sizeByte = 512) {
    contentType = contentType || '';
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    try {
      for (let offset = 0; offset < byteCharacters.length; offset += sizeByte) {
        const slice = byteCharacters.slice(offset, offset + sizeByte);
        const byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);

      }

      // const blob = new Blob(byteArrays);
      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    }
    catch (err) {
      return err;
    }
  }

  async buscaEspacoDrive() {

    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.API_ENDPOINT.apiDrive + "/size", {
        params: {
          // id_empresa: this.userService.getEmpresaId().toString()
        }
      })
        .toPromise()
        .then(async (response: any) => {
          resolve(response);
        }).catch(error => {
          console.log(error);
          reject(error)
        });
    });
  }
}

@Injectable()
export class FiltroDeBusca {
  id_empresa: string;
  id_usuario: string;
  coluna: string = "nome";
  ordem: string = "0";
  condicao: string = "$";
  texto: string = "";
  data1: string;
  data2: string;
  status: string = "";
  id_tipoManutencao: string;
  Fprioridade: string;
  Fstatus: string = "";
  RegInicio: number = 1;
  RegFim: number = 10;
  linhasPorPagina: number = 50;

  constructor() {
    let userService: UsuarioService = new UsuarioService();
    this.id_usuario = userService.getUsuarioId().toString();
    this.id_empresa = userService.getEmpresaId().toString();
    this.data1 = new Date(new Date().setFullYear(new Date().getFullYear() - 10)).toLocaleDateString("pt-BR")
    this.data2 = new Date().toLocaleDateString("pt-BR");
  }
}

import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class PaginatorIntlService extends MatPaginatorIntl {

  translate: TranslateService;
  itemsPerPageLabel = '';
  nextPageLabel = '';
  previousPageLabel = '';

  getRangeLabel = function (page, pageSize, length) {

    const of = this.translate ? this.translate.instant('PAGINATOR.RANGE') : 'of';

    if (length === 0 || pageSize === 0) {
      return '0 ' + of + ' ' + length;
    }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' ' + of + ' ' + length;
  };

  injectTranslateService(translate: TranslateService) {
    this.translate = translate;

    this.translate.onLangChange.subscribe(() => {
      this.translateLabels();
    });

    this.translateLabels();
  }

  translateLabels() {
    this.itemsPerPageLabel = this.translate.instant('PAGINATOR.ITEMS_PER_PAGE');
    this.nextPageLabel = this.translate.instant('PAGINATOR.NEXT_PAGE');
    this.previousPageLabel = this.translate.instant('PAGINATOR.PREVIOUS_PAGE');
  }

}