import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { NgModule } from '@angular/core';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
    data: {
      breadcrumb: "LOGIN"
    }
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
    // canActivate: [AuthGuard],
    data: {
      breadcrumb: "HOME"
    }
  },
  {
    path: 'esqueci-senha',
    loadChildren: () => import('./pages/esqueci-senha/esqueci-senha.module').then(m => m.EsqueciSenhaPageModule),
    data: {
      breadcrumb: "ESQUECI_MINHA_SENHA"
    }
  },
  {
    path: 'recupera-senha/:token',
    loadChildren: () => import('./pages/recupera-senha/recupera-senha.module').then(m => m.RecuperaSenhaPageModule),
    data: {
      breadcrumb: "RECUPERA_MINHA_SENHA"
    }
  },
  {
    path: 'tabelas',
    loadChildren: () => import('./pages/tabelas/tabelas.module').then(m => m.TabelasModule),
    // // canActivate: [AuthGuard],
    data: {
      breadcrumb: "TABELAS"
    }
  },
  {
    path: 'administracao',
    loadChildren: () => import('./pages/administracao/administracao.module').then(m => m.AdministracaoModule),
    // // canActivate: [AuthGuard],
    data: {
      breadcrumb: "ADMINISTRAÇÃO"
    }
  },
  {
    path: 'operacoes',
    loadChildren: () => import('./pages/operacoes/operacoes.module').then(m => m.OperacoesModule),
    // // canActivate: [AuthGuard],
    data: {
      breadcrumb: "OPERAÇÕES"
    }
  },
  {
    path: 'relatorios',
    loadChildren: () => import('./pages/relatorios/relatorios.module').then(m => m.RelatoriosModule),
    // canActivate: [AuthGuard],
    data: {
      breadcrumb: "RELATÓRIOS"
    }
  },
  {
    path: 'minha-conta',
    loadChildren: () => import('./pages/minha-conta/minha-conta.module').then(m => m.MinhaContaModule),
    // // canActivate: [AuthGuard],
    data: {
      breadcrumb: "MINHA_CONTA"
    }
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/404',
    // loadChildren: () => import('./pages/not-found/not-found.module').then(m => m.NotFoundPageModule)
  },
  {
    path: '404',
    loadChildren: () => import('./pages/not-found/not-found.module').then(m => m.NotFoundPageModule)
  },
 
  // {
  //   path: 'clientes',
  //   loadChildren: () => import('./pages/tabelas/clientes/clientes.module').then( m => m.ClientesPageModule)
  // },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
