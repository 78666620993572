import { AuthenticationService } from '../../authentication/authentication.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class DacService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
  ) { }

  async getDac(id_operacao: string): Promise<any> {
    const user: any = await this.authService.getUserByLogin(this.authService);

    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.API_ENDPOINT.apiDac, {
        params: {
          "id_operacao": id_operacao
        }
        // "id_operacao": "291" }
      })
        .toPromise()
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  }

  async getSaida(id_cda: string): Promise<any> {
    const user: any = await this.authService.getUserByLogin(this.authService);

    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.API_ENDPOINT.apiSaida + '/' + id_cda, {
      })
        .toPromise()
        .then(res => resolve(res))
        .catch(err => reject(err));
    })
  }

  async addDac(dac: Dac): Promise<any> {
    const user: any = await this.authService.getUserByLogin(this.authService);
    dac.id_usuario = user.idUsuario;
    dac.id_empresa = user.idEmpresa;

    return new Promise((resolve, reject) => {
      this.httpClient.post(environment.API_ENDPOINT.apiDac, dac)
        .toPromise()
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  }

  async addSaida(saida: Saida): Promise<any> {
    const user: any = await this.authService.getUserByLogin(this.authService);
    saida.id_usuario = user.idUsuario;
    saida.id_empresa = user.idEmpresa;

    return new Promise((resolve, reject) => {
      this.httpClient.post(environment.API_ENDPOINT.apiSaida, saida)
        .toPromise()
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  }


  async editDac(dac: Dac): Promise<any> {
    const user: any = await this.authService.getUserByLogin(this.authService);
    dac.id_usuario = user.idUsuario;
    dac.id_empresa = user.idEmpresa;

    return new Promise((resolve, reject) => {
      this.httpClient.put(environment.API_ENDPOINT.apiDac, dac)
        .toPromise()
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  }

  async editSaida(saida: Saida): Promise<any> {
    const user: any = await this.authService.getUserByLogin(this.authService);
    saida.id_usuario = user.idUsuario;
    saida.id_empresa = user.idEmpresa;

    return new Promise((resolve, reject) => {
      this.httpClient.put(environment.API_ENDPOINT.apiSaida, saida)
        .toPromise()
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  }

  async delDac(dac: Dac): Promise<any> {
    const user: any = await this.authService.getUserByLogin(this.authService);
    dac.id_usuario = user.idUsuario;
    dac.id_empresa = user.idEmpresa;

    return new Promise((resolve, reject) => {
      this.httpClient.delete(environment.API_ENDPOINT.apiDac, {
        params: {
          "id_empresa": user.idEmpresa.toString(),
          "id_usuario": user.idUsuario.toString(),
          "id_cda": dac.id_cda.toString(),
        }
      })
        .toPromise()
        .then(res => resolve(res))
        .catch(err => reject(err));
    })
  }

}

@Injectable()
export class Dac {
  id_usuario: number = 0;
  id_empresa: number = 0;
  id_operacao: number = 0;
  id_cda: number;
  numero: string;
  data: string;
  validade: string;
  local: string;
  id_clienteVendedor: number;
  id_mandatario: number;
  id_comprador: number;
  pesoLiquido: number;
  pesoBruto: number;
  quantidade: number;
  valorVenda: number;
  due: string;
  ncm: string;
}

@Injectable()
export class Saida {
  id_usuario: number = 0;
  id_empresa: number = 0;
  id_cdaSaida: number = 0;
  id_cda: number;
  tipoOperacao: number;
  tipoSaida: number;
  local: string;
  numeroSaida: string;
  dataSaida: string;
  numeroNE: string;
  dataNE: string;
  numeroDTT: string;
  dataDTT: string;
  quantidade: number;
  pesoBruto: number;
  pesoLiquido: number;
  valorVenda: number;
  id_clienteVendedor: number;
  id_mandatario: number;
  id_comprador: number;
  numeroConhecimentoCarga: string;
  saldoMercadoria: number;
  valorSaldo: number;
}
