import { UtilService } from 'src/app/services/util/util.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Router, NavigationEnd, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { KpiService } from 'src/app/services/kpi/kpi.service';
// import '../../../../../theme/echarts-theme.js';
import 'src/theme/echarts-theme.js'


@Component({
  selector: 'app-grafico-operecaoes-concluidas',
  templateUrl: './grafico-operecaoes-concluidas.component.html',
  styleUrls: ['./grafico-operecaoes-concluidas.component.scss']
})

export class GraficoOperacoesConluidasComponent implements OnInit {
  @Input()id_operacao:number=0;
  @Output()closeChart = new EventEmitter()
  // private previousUrl: string;
  public echartsInstanceEtapasOperacionais: any;
  public etapasOperacionaisChartOption = {
    tooltip: {
      position: 'top',
      trigger: 'axis',
      axisPointer: {
        type: 'none'
      },
    },
    toolbox: {
      show: true,
      showTitle: false,
      feature: {
        magicType: {
          type: ["stack"],
          title: ["a", "b"]
        },
        saveAsImage: {
          title: "Save as image",
          name: "icheck4u"
        }
      }
    },
    legend: {
      type: 'plain',
      orient: 'vertical',
      right: 0,
      top: 30,
      data: [
        {
          name: 'Origem',
          icon: 'circle'
        },
        {
          name: 'Destino',
          icon: 'circle'
        }
      ]
    },
    grid: { containLabel: true },
    xAxis: {
      axisLine: {
        show: false
      },
      data: [],
      silent: false,
      splitLine: {
        show: false
      },
      axisLabel: {
        interval: 0,
        rotate: -30
      }
    },
    yAxis: {
      axisTick: {
        show: false
      },
      splitNumber: 4,
      axisLine: {
        show: false
      },
    },
    series: [
      {
        name: 'Origem',
        type: 'bar',
        data: [600, 400, 300, 500, 350, 400, 471],
        animationDelay: (idx) => idx * 10,
        itemStyle: {
          barBorderRadius: 6
        },
        barMaxWidth: 10
      },
      {
        name: 'Destino',
        type: 'bar',
        data: [300, 450, 320, 370, 290, 370, 500],
        animationDelay: (idx) => idx * 10,
        itemStyle: {
          barBorderRadius: 6
        },
        barMaxWidth: 10
      }
    ],
    animationEasing: 'elasticOut'
  };
  
  constructor(
    public styleSanitizer: DomSanitizer,
    public userService: UsuarioService,
    public utilService: UtilService,
    // private navCtrl: NavController,
    // router: Router,
    private kpiService: KpiService,
  ) {

  }

  ngOnInit() {
  }


  async updateEtapasOperacionaisChart(id_operacao: number = 0) {

    await this.kpiService.getEtapasOperacionais(this.id_operacao).then((res: any) => {

      // this.showChart = true;
      // setTimeout(() => {
      //   this.scrollKpi.scrollToBottom();
      // }, 100)

      this.etapasOperacionaisChartOption.xAxis.data = Object.values(res[0]);
      this.etapasOperacionaisChartOption.series[0].data = Object.values(res[1]).map((el: string) => (el == "" || el == " ") ? 0 : parseFloat(el.replace(',', '.')));
      this.etapasOperacionaisChartOption.series[1].data = Object.values(res[2]).map((el: string) => (el == "" || el == " ") ? 0 : parseFloat(el.replace(',', '.')));

      this.echartsInstanceEtapasOperacionais.setOption(this.etapasOperacionaisChartOption, true);
      this.echartsInstanceEtapasOperacionais.resize();
    }).catch(console.log)

  }

  async onEtapasOperacionaisChartInit(event) {

    this.echartsInstanceEtapasOperacionais = event;
    await this.kpiService.getEtapasOperacionais(0).then((res: any) => {

      this.etapasOperacionaisChartOption.xAxis.data = Object.values(res[0]);
      this.etapasOperacionaisChartOption.series[0].data = Object.values(res[1]).map((el: string) => (el == "" || el == " ") ? 0 : parseFloat(el.replace(',', '.')));
      this.etapasOperacionaisChartOption.series[1].data = Object.values(res[2]).map((el: string) => (el == "" || el == " ") ? 0 : parseFloat(el.replace(',', '.')));

      this.echartsInstanceEtapasOperacionais.setOption(this.etapasOperacionaisChartOption, true);
      this.echartsInstanceEtapasOperacionais.resize();
    }).catch(console.log)

  }

  close(){
    this.closeChart.emit(false);
  }

}



// previousUrl: string;
// constructor(router: Router) {
//   router.events
//   .filter(event => event instanceof NavigationEnd)
//   .subscribe(e => {
//     console.log('prev:', this.previousUrl);
//     this.previousUrl = e.url;
//   });
// }
