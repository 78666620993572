import { AuthenticationService } from './services/authentication/authentication.service';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { PalleteService } from './services/palette/palette.service';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { Platform, MenuController } from '@ionic/angular';
import { Component } from '@angular/core';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent {

  public dark: boolean = false;

  constructor(
    private screenOrientation: ScreenOrientation,
    private colorPalettesService: PalleteService,
    public authService: AuthenticationService,
    public translate: TranslateService,
    private splashScreen: SplashScreen,
    private platform: Platform,
    private storage: Storage,
  ) {
    this.initializeApp();
    this.setLanguage();
  }

  initializeApp() {
    this.colorPalettesService.setPalette("palette-1");
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        this.splashScreen.hide();
        this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
      }
    });

  }

  async setLanguage() {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    let storageLang = await this.storage.get("LANG");
    if (storageLang == "en" || storageLang == "pt"){
      this.translate.use(storageLang);
    }
    else{
      this.translate.use("pt");
    }
  }

}
