import { PalleteService } from './../../services/palette/palette.service';
import { Storage } from '@ionic/storage';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-theme-toggle',
  templateUrl: './theme-toggle.component.html',
  styleUrls: ['./theme-toggle.component.scss'],
})

export class ThemeToggleComponent implements OnInit {

  @Input("labeled") showLabel: boolean = true;
  public dark: boolean = false;

  constructor(
    public storage: Storage,
    private colorPalettesService: PalleteService
  ) {
    this.storage.get("dark-theme").then(res => {
      this.dark = res;
      this.toggleTheme()
      // document.getElementsByTagName("ion-app")[0].classList.toggle("dark-theme", this.dark);
      // document.querySelectorAll(".icon-ship > img").forEach(el => el["style"].filter = `invert(${this.dark ? '1' : '0'})`)
    })
  }

  ngOnInit() {
    // this.toggleTheme()
  }

  toggleTheme() {
    // document.getElementsByTagName("ion-app")[0].classList.toggle("dark-theme", this.dark);
    //document.querySelectorAll(".icon-ship > img").forEach(el => el["style"].filter = `invert(${this.dark ? '1' : '0'})`)
    this.storage.set("dark-theme", this.dark)

    document.querySelectorAll(".img-dark").forEach(el => {
      const oldPath = el.getAttribute("src");
      if (this.dark && oldPath.split('.')[0].indexOf("_dark") < 0) el.setAttribute("src", oldPath.split('.')[0] + "_dark." + oldPath.split('.')[1])
      else el.setAttribute("src", oldPath.replace("_dark", ''))
    })

    if (this.dark) {
      this.colorPalettesService.setPalette("palette-1-dark");
    }
    else {
      this.colorPalettesService.setPalette("palette-1");
    }
  }

}
