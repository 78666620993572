import { AuthenticationService } from './../../services/authentication/authentication.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {

  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {
  }

  ngOnInit() { }

  logOut() {
    if (environment.cookie) {
      this.authService.logoutCookie();
    }
    else {
      this.authService.logout();
    }
  }

  goHome() {
    this.router.navigate(['home'])
  }
}
