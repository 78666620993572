import { Parada, TipoParada } from './../../services/tabelas/paradas/paradas.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-interrupcao',
  templateUrl: './interrupcao.component.html',
  styleUrls: ['./interrupcao.component.scss'],
})
export class InterrupcaoComponent implements OnInit {
  @ViewChild('input', { static: true }) input: ElementRef;

  public title: string;
  public tipo: string;
  public operacao: any;
  public motivosParada: TipoParada[] = [
  ];
  public motivoParada: any;
  public paradasService: any;
  public inputDataObj = {
    descricao: "",
  }

  public parada: Parada;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<InterrupcaoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = data.title;
    this.tipo = data.tipo;
    this.operacao = data.operacao;
    this.paradasService = data.paradasService;

    this.parada = {
      id_tipoParada: 0,
      descricao: "",
      id_operacao: this.operacao.id_operacao,
      id_operacaoEvento: this.tipo == "O" ? this.operacao.id_operacaoEventoOrigem : this.operacao.id_operacaoEventoDestino
    }

    if (this.tipo == "O") {
      // this.lotes = JSON.parse(this.operacao.lotesVinculados);
    }
    else {
      // this.lotes = JSON.parse(this.operacao.lotesVinculados)
    }
  }


  ngOnInit() {
    this.paradasService.getTiposParada().then((res) => {
      this.motivosParada = res;
    }).catch((err) => {
      //console.log(err)
    })

  }

  teste(a) {
    //console.log(a)
  }
}
