import { FormGroup, ValidatorFn, AbstractControl, FormControl } from '@angular/forms';
import { trigger, transition, style, animate } from '@angular/animations';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class FormUtilService {

  private formGroupAdd: FormGroup;
  private formGroupEdit: FormGroup;

  constructor(
    _formGroupAdd: FormGroup,
    _formGroupEdit: FormGroup,
  ) {
    this.formGroupAdd = _formGroupAdd;
    this.formGroupEdit = _formGroupEdit;
  }

  isInvalid(controlName: string, addOrEdit: "add" | "edit") {
    if (addOrEdit == "add") {
      return ((this.formGroupAdd.get(controlName).invalid) && (this.formGroupAdd.get(controlName).touched));
    }
    else {
      return ((this.formGroupEdit.get(controlName).invalid) && (this.formGroupEdit.get(controlName).touched));
    }
  }

  isValid(controlName: string, addOrEdit: "add" | "edit", debug: boolean = false) {
    if (addOrEdit == "add") {
      return ((this.formGroupAdd.get(controlName).valid) && (this.formGroupAdd.get(controlName).touched));
    }
    else {
      return ((this.formGroupEdit.get(controlName).valid) && (this.formGroupEdit.get(controlName).touched));
    }
  }

  hasError(controlName: string, validationType: string, addOrEdit: "add" | "edit", debug: boolean = false) {
    if (addOrEdit == "add") {
      return this.formGroupAdd.get(controlName).hasError(validationType) && (this.formGroupAdd.get(controlName).dirty || this.formGroupAdd.get(controlName).touched);
    }
    else {
      return this.formGroupEdit.get(controlName).hasError(validationType) && (this.formGroupEdit.get(controlName).dirty || this.formGroupEdit.get(controlName).touched);
    }
  }
  validateFields(): void {
    if (!this.formGroupAdd.valid) {
      this.formGroupAdd.markAsTouched();
      for (const control in this.formGroupAdd.controls) {
        if (this.formGroupAdd.controls.hasOwnProperty(control)) {
          this.formGroupAdd.controls[control].markAllAsTouched();
          this.formGroupAdd.controls[control].markAsDirty();
        }
      }
    }
  }

  selFirstIfOnly(controlName, array: any[]) {

    console.log(this.formGroupAdd.get(controlName))
    if (array.length == 1)
      this.formGroupAdd.controls[controlName].setValue(array[0]);

  }
}

export function alreadyExists(array: string[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const exists: boolean = array.find(element => element.toUpperCase() === control.value.toUpperCase()) != undefined;
    return exists ? { 'already_Exists': { value: control.value } } : null;
  };
}

export const ipValidator = (control: AbstractControl): { [key: string]: any } | null => {
  if (control.value.length <= 0) return null;
  const regEx = /\b(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\b/g;
  const valid = !regEx.test(control.value)
  return valid ? { 'invalid_ip': { value: control.value } } : null;
};

export function alreadyExistsEdit(array: string[], oldValue: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (oldValue.toUpperCase() === control.value.toUpperCase()) return null
    const exists: boolean = array.find(element => element.toUpperCase() === control.value.toUpperCase()) != undefined;
    return exists ? { 'already_Exists': { value: control.value } } : null;
  };
}

// export function selFirstIfOnly(array: any[]) {
//   return (control: AbstractControl): any | null => {
//     console.log(control,array)
//     if (array.length == 4) control.setValue(array[0]);
//     return null;
//   };
// }

export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateX(-100%)' }),
    animate('500ms', style({ opacity: 1, transform: 'translateX(0%)' }))
  ]),
  transition(':leave',
    [
      style({ opacity: 1 }),
      animate('300ms', style({ opacity: 0, transform: 'translateX(-100%)' }))
    ])
]);

@Injectable()
export class EqualityValidator {
  // If our validation fails, we return an object with a key for the error name and a value of true.
  // Otherwise, if the validation passes, we simply return null because there is no error.
  static areNotEqual(formGroup: FormGroup) {
    let value: any;
    let valid = true;

    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: FormControl = formGroup.controls[key] as FormControl;

        if (value === undefined) {
          value = control.value;
        } else {
          if (value !== control.value) {
            valid = false;
            break;
          }
        }
      }
    }

    if (valid) {
      return null;
    }

    return {
      areNotEqual: true
    };
  }
}