import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit, Inject } from '@angular/core';
import { ConfirmaPontoControleManualComponent } from '../confirma-ponto-controle-manual/confirma-ponto-controle-manual.component';

export type TipoOperacao = "O" | "D";

@Component({
  selector: 'app-pratico-select',
  templateUrl: './pratico-select.component.html',
  styleUrls: ['./pratico-select.component.scss'],
})

export class PraticoSelectComponent implements OnInit {

  public praticos: Array<any> = [];
  public praticosChecked: Array<any> = [];
  public praticosFiltrados: Array<any> = [];
  public tipo: TipoOperacao;
  public dataFim: String;

  public praticosaBordo = [];
  public complementos:
    {
      id_operacaoEventoComplemento: number,

      confirmado: number,
      descricao: string,
      isChecked: boolean
    }[] = [

    ];

  public eventoOperacaoAtual: string = '';

  constructor(
    public dialogRef: MatDialogRef<PraticoSelectComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public snackBar: MatSnackBar
  ) {
    if(this.data.praticos){
      this.praticos = this.data.praticos;
      this.praticosFiltrados = this.data.praticos;
    }else {
      this.praticos = this.data.rebocadores;
      this.praticosFiltrados = this.data.rebocadores;
    }
  }

  ngOnInit() {
    let lista: Array<string>;
    //console.log(this.praticos)
    if(this.praticos.length > 0){
      this.data.service.getEvento({
        id_operacao: this.praticos[0].id_operacao,
        id_operacaoEvento: this.praticos[0].id_operacaoEvento
      }).then((res) => {
        //console.log(res)
        if(res[0].listaRebocador){
          lista = res[0].listaRebocador.split(';');
        }else {
          lista = res[0].listaPratico.split(';');
        }

        this.praticos.forEach((content) => {
          lista.forEach((checcked) => {
            if((content.id_pratico || content.id_rebocador) === +checcked){
              this.praticosaBordo.push(content.id_pratico || content.id_rebocador);
              content.isChecked = true;
              content.ordem = lista.indexOf(checcked) + 1;
              this.praticosChecked.push(content)
            }
          })
        })

      })
    }
  }

  applyFilter(ev){
    // ev = ev.toLowerCase();
    // console.log(ev)
    this.praticosFiltrados = this.praticos.filter(filtered => filtered.nome.toLowerCase().indexOf(ev.toLowerCase()) > -1)
  }

  ///////////////////////////////// ABRE DE REGISTRO DE HORA ///////////////////////////////
  async checkComplemento(complemento,descricao) {
    if (complemento.isChecked) {

      this.abreModalConfirmaPontoControleManual(descricao, true).afterClosed().subscribe(result => {
        if (result) {
          
          this.callCheckComplemntoPontoDeControle(complemento, result.dataFim);
        }
        else {
          complemento.isChecked = !complemento.isChecked;
        }
      });

    }
    else {
      this.callCheckComplemntoPontoDeControle(complemento);
    }

  }

  callCheckComplemntoPontoDeControle(complemento, dataFim?) {
    //console.log(this.data.praticos)
    //console.log(this.data.rebocadores)
    if(!complemento.isChecked){
      complemento.ordem = 0;
      let index = this.praticosaBordo.indexOf(complemento.id_pratico || complemento.id_rebocador)
      this.praticosaBordo.splice(index, 1);
      this.praticosChecked.splice(this.praticosaBordo.indexOf(complemento), 1);
    } else {
      this.praticosaBordo.push(complemento.id_pratico || complemento.id_rebocador);
      this.praticosChecked.push(complemento)
    }

    this.praticos.forEach((content) => {
      this.praticosaBordo.forEach((checcked) => {
        if((content.id_pratico || content.id_rebocador) === +checcked){
          content.isChecked = true;
          content.ordem = this.praticosaBordo.indexOf(checcked) + 1;
        }
      })
    })
  }

  async concluirOperacao() {
    this.abreModalConfirmaPontoControleManual(this.data.title, false).afterClosed().subscribe(result => {
      if (result) {
        let listaPraticos = this.praticosaBordo.join(";");
        //console.log(listaPraticos)
        this.data.service.concluiEvento(
          this.praticos[0].id_operacao,
          this.praticos[0].id_operacaoEvento,
          listaPraticos, 
          result.dataFim
        ).then(res => {
          //console.log(res);
          this.abreModalConfirmaPontoControleManual("teste", false).close(true);
          this.dialogRef.close(true);
        }).catch(err => {
          //console.log(err)
        });
      }
    });

  }

  abreModalConfirmaPontoControleManual(descricao: string, dataOn: boolean): MatDialogRef<ConfirmaPontoControleManualComponent> {
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.data = {
      title: descricao,
      input: dataOn
    };
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;

    return this.dialog.open(ConfirmaPontoControleManualComponent, dialogConfig);

  }

  tudoCheckado(): boolean {
    return false
    //this.complementos.map(el => el.isChecked).some(el => !el)
  }

}

