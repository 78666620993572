import { NgxUiLoaderModule, NgxUiLoaderConfig, POSITION, SPINNER, PB_DIRECTION } from 'ngx-ui-loader';
import { AuthenticationService } from './services/authentication/authentication.service';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TokenInterceptor } from './interceptors/token-interceptor.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomComponentModule } from './components/custom-components.module';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { PaginatorIntlService } from './services/util/util.service';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SuperTabsModule } from '@ionic-super-tabs/angular';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppRoutingModule } from './app-routing.module';
import { RouteReuseStrategy } from '@angular/router';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { NgModule, LOCALE_ID } from '@angular/core';
import { IonicStorageModule } from '@ionic/storage';
import { Camera } from '@ionic-native/camera/ngx';
import { AuthGuard } from './guards/auth.guard';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { CurrencyPipe, DecimalPipe } from '@angular/common'
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const config: SocketIoConfig = {
  url: environment.getWSUrl(), options: { 'path': '/ic4u/socket.io/painel', 'transports': ['websocket'] }
}

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: 'red',
  fgsColor: "#903f98",
  bgsPosition: POSITION.bottomCenter,
  bgsSize: 40,
  bgsType: SPINNER.rectangleBounce,
  pbDirection: PB_DIRECTION.leftToRight,
  pbThickness: 5,
  logoUrl: "assets/images/logo/icon.png",
  fgsType: "circle",
  overlayColor: "rgba(255, 255, 255, 0.8)",
  logoSize: 170,
  fgsSize: 100,
  blur: 10,
  hasProgressBar: true,
  pbColor: "#903f98",
};

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    // ApmModule,
    FormsModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CustomComponentModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    SuperTabsModule.forRoot(),
    IonicModule.forRoot(),
    IonicStorageModule.forRoot({
      name: 'ic4u-app-storage',
      storeName: 'ic4u-app-storage'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    SocketIoModule.forRoot(config),
  ],
  providers: [
    Camera,
    AuthGuard,
    StatusBar,
    // ApmService,
    CurrencyPipe,
    DecimalPipe,
    SplashScreen,
    HttpClientModule,
    ScreenOrientation,
    AuthenticationService,
    InAppBrowser,
    // { provide: LOCALE_ID, useValue: 'pt-PT' },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      provide: MatPaginatorIntl,
      useFactory: (translate) => {
        const service = new PaginatorIntlService();
        service.injectTranslateService(translate);
        return service;
      },
      deps: [TranslateService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  // constructor(service: ApmService) {
  //   // Agent API is exposed through this apm instance
  //   const apm = service.init({
  //     serviceName: 'ic4uDesktop',
  //     serverUrl: 'https://38e895ec1ac54e499eabf330323afe73.apm.eastus2.azure.elastic-cloud.com:443',
  //     environment: environment.production ? 'production' : 'development'
  //   })

  //   apm.setUserContext({
  //     'username': 'foo',
  //     'id': 'bar'
  //   })
  // }
}
