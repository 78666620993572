import { AuthenticationService } from '../../authentication/authentication.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class MandatariosService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
  ) { }

  async getMandatarios(): Promise<any> {
    const user: any = await this.authService.getUserByLogin(this.authService);
    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.API_ENDPOINT.apiMandatarios)
        .toPromise()
        .then(res => {console.log(res); resolve(res)})
        .catch(err => reject(err));
    })
  }

  async addMandatarios(mandatario: Mandatario): Promise<any> {
    const user: any = await this.authService.getUserByLogin(this.authService);
    mandatario.id_usuario = user.idUsuario;
    mandatario.id_empresa = user.idEmpresa;
    console.log(mandatario);

    return new Promise((resolve, reject) => {
      this.httpClient.post(environment.API_ENDPOINT.apiMandatarios, mandatario)
        .toPromise()
        .then(res => resolve(res))
        .catch(err => reject(err));
    })
  }

  async editMandatarios(mandatario: Mandatario): Promise<any> {
    const user: any = await this.authService.getUserByLogin(this.authService);
    mandatario.id_usuario = user.idUsuario;
    mandatario.id_empresa = user.idEmpresa;
    console.log(mandatario);

    return new Promise((resolve, reject) => {
      this.httpClient.put(environment.API_ENDPOINT.apiMandatarios, mandatario)
        .toPromise()
        .then(res => resolve(res))
        .catch(err => reject(err));
    })
  }

  async delMandatarios(mandatario: Mandatario): Promise<any> {
    const user: any = await this.authService.getUserByLogin(this.authService);
    mandatario.id_usuario = user.idUsuario;
    mandatario.id_empresa = user.idEmpresa;
    console.log(mandatario);

    return new Promise((resolve, reject) => {
      this.httpClient.delete(environment.API_ENDPOINT.apiMandatarios, {
        params: {
          "id_empresa": user.idEmpresa.toString(),
          "id_usuario": user.idUsuario.toString(),
          "id_mandatario": mandatario.id_mandatario.toString(),
        }
      })
        .toPromise()
        .then(res => resolve(res))
        .catch(err => reject(err));
    })
  }

}

@Injectable()
export class Mandatario {
  id_usuario: number = 0;
  id_empresa: number = 0;
  id_mandatario: number;
  nome: string;
  documento: string;
  endereco: string;
  numero: string;
  complemento: string;
  bairro: string;
  cidade: string;
  estado: string;
  cep: string;
}
