import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit, Inject } from '@angular/core';
import { ConfirmaPontoControleManualComponent } from '../confirma-ponto-controle-manual/confirma-ponto-controle-manual.component';
import { PartialObserver } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CustomAlertService } from 'src/app/services/custom-alert/custom-alert.service';
import { AlertTemplateComponent } from '../alert-template/alert-template.component';

export type TipoOperacao = "O" | "D";

@Component({
  selector: 'app-complemento-ponto-de-controle',
  templateUrl: './complemento-ponto-de-controle.component.html',
  styleUrls: ['./complemento-ponto-de-controle.component.scss'],
})

export class ComplementoPontoDeControleComponent implements OnInit {

  public confirmarBombear = true;
  public tipo: TipoOperacao;
  public dataFim: String;
  public complementos:
    {
      id_operacaoEventoComplemento: number,

      confirmado: number,
      descricao: string,
      isChecked: boolean
    }[] = [

    ];

  public eventoOperacaoAtual: string = '';
  // dialogRef: MatDialogRef<ComplementoPontoDeControleComponent>;

  constructor(
    public dialogRef: MatDialogRef<ComplementoPontoDeControleComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public snackBar: MatSnackBar,
  ) {
    // setInterval(() => console.log(this.complementos),1000)

    // this.dataFim = this.data.dataFim;
    this.tipo = this.data.tipo;
    this.eventoOperacaoAtual = this.tipo == "O" ? this.data.operacao.origemEventoAtual : this.data.operacao.destinoEventoAtual;
    if (this.tipo == "O") {
      this.complementos = JSON.parse(this.data.operacao.origemEventoComplemento);
    }
    else {
      this.complementos = JSON.parse(this.data.operacao.destinoEventoComplemento)
    }
    this.complementos = this.complementos.map((el) => {
      return {
        ...el,
        isChecked: el.confirmado == 1 ? true : false
      }
    })
  }

  ngOnInit() {
    // console.log(this.data.operacao)
    // this.data.service.getCheckComplemntoPontoDeControle(this.data.operacao).
    // then((res:any)=>{ 
    //   console.log(res)
    // })
    // .catch(err=>{
    //   console.log(err)
    // })
  }

  async checkComplemento(complemento,descricao) {
    if (complemento.isChecked) {

      this.abreModalConfirmaPontoControleManual(descricao, true).afterClosed().subscribe(result => {
        if (result) {
          
          this.callCheckComplemntoPontoDeControle(complemento.id_operacaoEventoComplemento, this.data.operacao.id_operacao, this.tipo, complemento.isChecked, result.dataFim);
        }
        else {
          complemento.isChecked = !complemento.isChecked;
        }
      });

    }
    else {
      this.callCheckComplemntoPontoDeControle(complemento.id_operacaoEventoComplemento, this.data.operacao.id_operacao, this.tipo, complemento.isChecked);
    }

  }

  callCheckComplemntoPontoDeControle(id_operacaoEventoComplemento, id_operacao, tipo, isChecked, dataFim?) {
    this.data.service.checkComplemntoPontoDeControle(
      // id_pontosControleComplemento
      //  [{"id_operacaoEventoComplemento":11,"confirmado":0,"descricao":"Medição dos tanques e amostragem"},{"id_operacaoEventoComplemento":12,"confirmado":0,"descricao":"ISGOTT Checklist"},{"id_operacaoEventoComplemento":13,"confirmado":1,"descricao":"Inspeções gerais"},{"id_operacaoEventoComplemento":14,"confirmado":1,"descricao":"Aceite NOR"}]
      id_operacaoEventoComplemento,
      id_operacao,
      tipo,
      isChecked,
      dataFim
    ).then(res => {
      res = res.map((el) => {
        return {
          ...el,
          isChecked: el.confirmado == 1 ? true : false
        }
      })
      this.complementos = res;
    }).catch(err => console.log(err))
  }

  async concluirOperacao() {
    const title = this.tipo == "O"? this.data.operacao.origemEventoAtual:this.data.operacao.destinoEventoAtual;
    this.abreModalConfirmaPontoControleManual(title, true).afterClosed().subscribe(result => {
      if (result) {
        let operacao = this.data.operacao;
        this.data.service.concluiEvento(
          operacao.id_operacao,
          this.tipo == "O" ? operacao.id_operacaoEventoOrigem : operacao.id_operacaoEventoDestino,
          this.tipo, 0, result.dataFim
        )
          .then(res => {
            //console.log(res);
            this.abreModalConfirmaPontoControleManual("teste", false).close(true);
            this.dialogRef.close(true);
          })
          .catch(err => {
            //console.log(err)
            const dialogRef = this.dialog.open(AlertTemplateComponent, {
              data: {
                iconType: 'error',
                iconPosition: 'overflow',
                title:"ALERT.ERRO",
                text: "Algo deu errado e sua operação não foi concluída. \n" + err.error.message,
                button: 'Ok',
              },
              autoFocus: false,
              panelClass: ['overflow-icon-alert-wrapper']
            })
            dialogRef.afterClosed().subscribe(result => {})
          })
      }
    })
  }

  abreModalConfirmaPontoControleManual(descricao: string, dataOn: boolean): MatDialogRef<ConfirmaPontoControleManualComponent> {
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.data = {
      title: descricao,
      input: dataOn
    };
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;

    return this.dialog.open(ConfirmaPontoControleManualComponent, dialogConfig);

  }

  teste(){
    //console.log()
    this.confirmarBombear = false;
  }

  tudoCheckado(): boolean {
    return false
    //this.complementos.map(el => el.isChecked).some(el => !el)
  }

}

