import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { UtilService } from 'src/app/services/util/util.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})

export class SideMenuComponent implements OnInit {

  public open: boolean = false;
  public parentPages = [];
  public menuPages = [];

  constructor(
    private router: Router,
    private utilService: UtilService,
    public userService: UsuarioService,
  ) {
    this.menuPages.filter(page => page.tipo == "S").forEach((item, index) => {
      this.parentPages[index] = {};
      Object.assign(this.parentPages[index], item);
    });
  }

  ngOnInit() {
    this.utilService.getMenu().then((res: any) => {
      this.menuPages = res;
      console.log(this.menuPages)
      this.menuPages.filter(page => page.tipo == "S").forEach((item, index) => {
        this.parentPages[index] = {};
        Object.assign(this.parentPages[index], item);
      })
    }).catch(err => console.log(err))
  }

  toggleMenu() {
    this.open = !this.open;
    if (this.open) {
      document.getElementById("main-menu")["style"].width = "15vw";
      document.getElementById("main-menu")["style"].minWidth = "15vw";
      document.getElementById("main-menu")["style"].maxWidth = "15vw";
      this.utilService.getMenu().then((res: any) => {
        this.menuPages = res;
        this.menuPages.filter(page => page.tipo == "S").forEach((item, index) => {
          this.parentPages[index] = {};
          Object.assign(this.parentPages[index], item);
        })
      }).catch(err => console.log(err))
    }
    else {
      document.getElementById("main-menu")["style"].width = "3vw";
      document.getElementById("main-menu")["style"].minWidth = "3vw";
      document.getElementById("main-menu")["style"].maxWidth = "3vw";
    }
  }

  getItemData(id) {
    return this.menuPages.filter(item => item.id === id)[0];
  }

  parentClick(parentItem) {
    if (parentItem.subOpcoes.length) {
      this.parentPages.filter(item => item.id === parentItem.id)[0].subOpcoes = [];
    }
    else {
      for (let i = 0; i < this.parentPages.length; i++) {
        this.parentPages[i].subOpcoes = [];
      }
      this.parentPages.filter(item => item.id === parentItem.id)[0].subOpcoes = this.getItemData(parentItem.id).subOpcoes;
    }
  }

  chlildClick(childItem, ev) {
    ev.stopPropagation();
    console.log(childItem);
    this.router.navigate([this.getItemData(childItem).url]);
  }

}
