import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { PerfilDeAcessoService } from '../services/administracao/perfil-de-acesso/perfil-de-acesso.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  constructor(
    private checkPerfilService: PerfilDeAcessoService,
    private usuarioService: UsuarioService,
    private router: Router,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise(async (resolve, reject) => {
      this.checkPerfilService.checkPerfil(route.data.id_menu)
        .then((res: any) => {
          if (!res.temPermissao) {
            resolve(false)
            this.router.navigate(['/404']);
          }
          else {
            if (this.usuarioService.getEmpresaId() == 858) {
              if (route.routeConfig.path == "operacao"){
                this.router.navigate(['/operacoes/operacao-s2t']);
              }
              if (route.routeConfig.path == "painel"){
                this.router.navigate(['/operacoes/painel-s2t']);
              }
            }
            if (this.usuarioService.getEmpresaId() == 860) {
              if (route.routeConfig.path == "operacao-s2t"){
                this.router.navigate(['/operacoes/operacao']);
              }
              if (route.routeConfig.path == "painel-s2t"){
                this.router.navigate(['/operacoes/painel']);
              }
            }
            resolve(true)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    })
  }
}
