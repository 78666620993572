import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit, Inject } from '@angular/core';

export type TipoOperacao = "O" | "D";

@Component({
  selector: 'app-check-lotes',
  templateUrl: './check-lotes.component.html',
  styleUrls: ['./check-lotes.component.scss'],
})

export class CheckLotesComponent implements OnInit {

  public tipo: TipoOperacao;
  public id_loteSelecionado: number = 0;
  public lotes:
    {
      id_operacaoLote: number,
      numero: string,
      processado: number,
      // isChecked: boolean
    }[] = [];
  public eventoOperacaoAtual: string = '';

  constructor(
    public dialogRef: MatDialogRef<CheckLotesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public snackBar: MatSnackBar,
  ) {
    this.tipo = this.data.tipo;
    this.eventoOperacaoAtual = this.tipo == "O" ? this.data.operacao.origemEventoAtual : this.data.operacao.destinoEventoAtual;
    if (this.tipo == "O") {
      this.lotes = JSON.parse(this.data.operacao.lotesVinculados);
    }
    else {
      this.lotes = JSON.parse(this.data.operacao.lotesVinculados)
    }
    if (this.data.type) {
      this.lotes = this.lotes.filter((el) => {
        if (el.processado === 1) {
          return el
        }
      })
    }
    else {
      this.lotes = this.lotes.filter((el) => {
        if (el.processado === 0) {
          return el
        }
      })
    }

  }

  ngOnInit() {
    if (this.lotes.length == 1) this.id_loteSelecionado = this.lotes[0].id_operacaoLote
  }

  checkLote(complemento) {
    this.id_loteSelecionado = complemento.id_operacaoLote
  }
}

