
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SignInModalComponent } from 'src/app/components/sign-in-modal/sign-in-modal.component';
import { UsuarioService, UsuarioDAO } from 'src/app/services/usuario/usuario.service';
import { BehaviorSubject, from as fromPromise, Observable, of, from } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoggedUserModel } from './logged-user.model';
// import { JwtHelperService } from '@auth0/angular-jwt';
import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Login } from './Login';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {

  // private isLoggedIn: boolean = false;
  public loggedUser: LoggedUserModel;
  public isLoggedInSubject = new BehaviorSubject<boolean>(false);

  constructor(
    public userService: UsuarioService,
    private httpClient: HttpClient,
    public dialog: MatDialog,
    private storage: Storage,
    private router: Router,
  ) {
    this.storage.set("UUID", uuidv4())
  }

  // isAuthenticated(): Promise<boolean> {

  //   return new Promise(async (resolve, reject) => {
  //     let token = await this.storage.get("ACCESS_TOKEN");
  //     const helper = new JwtHelperService();
  //     const isExpired = helper.isTokenExpired(token);
  //     if (isExpired) {
  //       this.isLoggedInSubject.next(false);
  //     }
  //     else {
  //       this.isLoggedInSubject.next(true);
  //     }
  //   })

  // }

  getToken() {
    return this.storage.get("ACCESS_TOKEN")
  }

  setUser(user: LoggedUserModel) {
    this.loggedUser = new LoggedUserModel();
    this.loggedUser = user;
    this.userService.setUsuario(user);
  }

  signin(usuario: Login, salvaSenha: boolean = false): Promise<any> {

    // usuario.codigo = "1034"; //Hauric
    // usuario.codigo = "9004"; //Wander
    usuario.codigo = usuario.codigo ? usuario.codigo : environment.empresa;
    usuario.id_empresa = environment.id_empresa;
    // if (environment.cookie) {
    return new Promise((resolve, reject) => {

      this.httpClient.post(environment.API_ENDPOINT.apiLogin, usuario)
        .toPromise()
        .then(async (response: any) => {
          if (response['senha'] === 1) {
            this.setUser(response);
          }
          if (response['token']) {
            if (!environment.cookie) {
              await this.storage.set("ACCESS_TOKEN", response['token']);
            }

            if (salvaSenha) {
              this.salvaUsuario(usuario);
              this.salvaModoLogin(1);
            }
            else {
              this.apagaUsuario();
              this.salvaModoLogin(0);
            }
            this.isLoggedInSubject.next(true);
            resolve(response);
          }
          if (response['senha'] == 0) {
            this.isLoggedInSubject.next(false);
            reject(response);
          }
          if (response['senha'] == 1) {

            this.isLoggedInSubject.next(true);
            if (salvaSenha) {
              this.salvaUsuario(usuario);
              this.salvaModoLogin(1);
            }
            else {
              this.apagaUsuario();
              this.salvaModoLogin(0);
            }
            resolve(response);
          }
        }).catch(error => {
          console.error(error)
          reject(error)
        })

    })
    // }

  }

  logout() {
    this.loggedUser = null;
    this.isLoggedInSubject.next(false);
    this.router.navigate(['/login']);
  }

  async logoutCookie(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.API_ENDPOINT.apiLogout)
        .toPromise()
        .then((res: any) => {
          resolve(res)
          this.loggedUser = null;
          this.isLoggedInSubject.next(false);
          this.router.navigate(['/login']);
        })
        .catch(err => {
          reject(err);
        })
    })
  }

  recoverPassword(dados: any): Promise<any> {

    let body = {
      ...dados,
      id_empresa: environment.id_empresa
    }
    body.codigo = body.codigo ? body.codigo : environment.empresa;
    return new Promise((resolve, reject) => {
      this.httpClient.post(environment.API_ENDPOINT.apiLogin + '/esqueci_senha', body)
        .toPromise()
        .then(res => resolve(res))
        .catch(err => reject(err));
    })
  }

  async salvaUsuario(usuario: Login) {
    await this.storage.set("LOGIN", btoa(usuario.login));
    await this.storage.set("SENHA", btoa(usuario.senha));
  }

  async apagaUsuario() {
    await this.storage.remove("LOGIN");
    await this.storage.remove("SENHA");
  }

  async salvaModoLogin(modoLogin: number) {
    switch (modoLogin) {
      case 0: //Limpa login
        await this.storage.set("LOGIN_MODE", btoa('false'));
        break;
      case 1: //Salva nome e senha
        await this.storage.set("LOGIN_MODE", btoa('true'));
        break;
    }
  }

  async temUsuarioSalvo() {
    let modoLogin = atob(await this.storage.get("LOGIN_MODE"));
    if (modoLogin == 'true') {
      return {
        login: atob(await this.storage.get("LOGIN")),
        senha: atob(await this.storage.get("SENHA"))
      }
    }
    else {
      return false;
    }
  }

  openSignInModal(route: ActivatedRouteSnapshot, state: RouterStateSnapshot, router: Router, service: any): void {
    const dialogRef = this.dialog.open(SignInModalComponent, {
      panelClass: 'sign-in-modal',
      hasBackdrop: false,
      data: { route, state, router, service }
    });

    dialogRef.afterClosed().subscribe(result => {3
      if (!result && route && state && router) {
        this.router.navigate(['/login']);
      }
    });
  }

  async getUserByLogin(service: any): Promise<UsuarioDAO | any> {
    if (this.userService.getUsuarioId() > 0) {
      return new Promise((resolve, reject) => {
        resolve(this.userService.getUsuario())
      })
    }
    else {
      const dialogRef = this.dialog.open(SignInModalComponent, {
        panelClass: 'sign-in-modal',
        hasBackdrop: false,
        data: { service, returnUser: true }
      });

      return await dialogRef.afterClosed().toPromise();
    }
  }

}