import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirma-ponto-controle-manual',
  templateUrl: './confirma-ponto-controle-manual.component.html',
  styleUrls: ['./confirma-ponto-controle-manual.component.scss'],
})
export class ConfirmaPontoControleManualComponent implements OnInit {
 
  public input: boolean;
  public dataFim: any;
  public horaFim: any;
  public title: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConfirmaPontoControleManualComponent>
  ) {

    this.title = data.title;
    this.input = data.input
  }

  ngOnInit() { 
    const d = new Date()
    this.dataFim = d.toLocaleDateString("pt-BR", {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
    this.horaFim = d.toLocaleTimeString('pt-BR', {
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  registrarDataFimEvento() {
    let dia = this.dataFim.split('/')[0];
    let mes = this.dataFim.split('/')[1];
    let ano = this.dataFim.split('/')[2];
    //console.log(this.horaFim)
    let dataFimDigitada =`${ano}-${mes}-${dia} ${this.horaFim}:00`;
    this.dialogRef.close({ dataFim: dataFimDigitada });
  }

}
