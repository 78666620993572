import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { LoggedUserModel } from './../authentication/logged-user.model';
import { Injectable } from '@angular/core';
import { SignInModalComponent } from 'src/app/components/sign-in-modal/sign-in-modal.component';
import { state } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  private usuario: UsuarioDAO = new UsuarioDAO();
  // private usuario: UsuarioDAO = {
  //   senha: 1,
  //   idUsuario: 8121,
  //   idEmpresa: 1283, //Bruno
  //   // idEmpresa: 148,     //Oil Tanking
  //   codigo: "00001655",
  //   trocaSenha: 0,
  //   nomeEmpresa: "BRUNO RAPHAEL FERREIRA DE SOUZA",
  //   sessao: "AbIb.AbBH.BAbB.AAIb-AbAbbbbAbFGEbE",
  //   nomeUsuario: "Daniel Zanutto",
  //   idCliente: 0,
  //   idDbEmpresa: 0,
  // };

  constructor(

  ) { }

  public getUsuarioId() {
    return this.usuario.idUsuario;
  }

  public getUsuario() {
    return this.usuario;
  }


  public getNome(): string {
    return this.getUsuario().nomeUsuario;
  }

  public getEmpresaId(): number {
    return this.usuario.idEmpresa;
  }

  public setUsuario(usuario: UsuarioDAO | LoggedUserModel) {
    // console.log(usuario)
    if (usuario instanceof UsuarioDAO)
      this.usuario = usuario;
    else {
      this.usuario.senha = usuario.senha;
      this.usuario.idUsuario = usuario.idUsuario;
      this.usuario.idEmpresa = usuario.idEmpresa;
      this.usuario.idCliente = usuario.idCliente;
      this.usuario.idDbEmpresa = usuario.idDbEmpresa;
      this.usuario.id_plantaPadrao = usuario.id_plantaPadrao;
      this.usuario.codigo = usuario.codigo;
      this.usuario.nomeEmpresa = usuario.nomeEmpresa;
      this.usuario.nomeUsuario = usuario.nomeUsuario;
      this.usuario.trocaSenha = usuario.trocaSenha;
      this.usuario.urlArquivo = usuario.urlArquivo;
      this.usuario.id_permissaoMaster = usuario.id_permissaoMaster;
    }
  }



}

@Injectable()
export class UsuarioDAO {

  public senha: number = 0;
  public idUsuario: number = 0;
  public idEmpresa: number = 0;
  public codigo: string = "";
  public trocaSenha: number = 0;
  public nomeEmpresa: string = "";
  public nomeUsuario: string = "";
  public idCliente: number = 0;
  public idDbEmpresa: number = 0;
  public id_plantaPadrao: number = 0;
  public token: string = "";
  public urlArquivo: string = "";
  public id_permissaoMaster:number

}

