import { UtilService } from 'src/app/services/util/util.service';
import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Router, NavigationEnd, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})

export class PageHeaderComponent implements OnInit {

  private previousUrl: string;
  public logo: string;
  
  constructor(
    public styleSanitizer: DomSanitizer,
    public userService: UsuarioService,
    public utilService: UtilService,
    private navCtrl: NavController,
    router: Router,
  ) {

  }

  ngOnInit() {
    if (this.userService.getUsuario().urlArquivo) {
      this.utilService.buscaLogo(this.userService.getUsuario().urlArquivo)
        .then((res: any) => {
          this.logo = res
        })
        .catch((err) => {
          this.logo = '/assets/images/logo/icon.png'
        })
    }
    else {
      this.logo = '/assets/images/logo/icon.png'
    }

  }

  navBack() {
    this.navCtrl.back();
  }

}

// previousUrl: string;
// constructor(router: Router) {
//   router.events
//   .filter(event => event instanceof NavigationEnd)
//   .subscribe(e => {
//     console.log('prev:', this.previousUrl);
//     this.previousUrl = e.url;
//   });
// }
