import { AuthenticationService } from '../../authentication/authentication.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class ClientesService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
  ) { }
  
  async getClientes(): Promise<any> {
    const user: any = await this.authService.getUserByLogin(this.authService);
    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.API_ENDPOINT.apiClientes)
        .toPromise()
        .then(res => resolve(res))
        .catch(err => reject(err));
    })
  }

  async addCliente(cliente: ClienteRequest): Promise<any> {
    const user: any = await this.authService.getUserByLogin(this.authService);
    cliente.id_empresa = user.idEmpresa;
    return new Promise((resolve, reject) => {
      this.httpClient.post(environment.API_ENDPOINT.apiClientes,cliente)
        .toPromise()
        .then(res => resolve(res))
        .catch(err => reject(err));
    })
  }

  async editCliente(cliente: ClienteRequest): Promise<any> {
    const user: any = await this.authService.getUserByLogin(this.authService);
    // usuario.id_usuario = user.idUsuario;
    cliente.id_empresa = user.idEmpresa;
    console.log(cliente);

    return new Promise((resolve, reject) => {
      this.httpClient.put(environment.API_ENDPOINT.apiClientes, cliente)
        .toPromise()
        .then(res => resolve(res))
        .catch(err => reject(err));
    })
  }
  async delCliente(cliente : Cliente): Promise<any> {
    const user: any = await this.authService.getUserByLogin(this.authService);
    

    return new Promise((resolve, reject) => {
      this.httpClient.delete(environment.API_ENDPOINT.apiClientes, {
        params: {
          "id_empresa": user.idEmpresa.toString(),
          "id_cliente": cliente.id_cliente.toString(),
        }
      })
        .toPromise()
        .then(res => resolve(res))
        .catch(err => reject(err));
    })
  }
  
}

@Injectable()
export class ClienteRequest {
  public id_cliente?: number = 0;
  public nome: string = "";
  public id_empresa?: number = 0;

}
@Injectable()
export class Cliente {

  public id_cliente?: number = 0;
  public nome: string = "";

}

