import { FormUtilService, fadeAnimation } from '../../services/util/form-util.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Dac, DacService, Saida } from 'src/app/services/operacoes/dac/dac.service';
import { Cliente, ClientesService } from 'src/app/services/tabelas/clientes/clientes.service';
import { Comprador, CompradoresService } from 'src/app/services/tabelas/compradores/compradores.service';
import { Mandatario, MandatariosService } from 'src/app/services/tabelas/mandatarios/mandatarios.service';
import { startWith, filter, map } from 'rxjs/operators';
import { CustomAlertService } from 'src/app/services/custom-alert/custom-alert.service';

@Component({
  selector: 'app-saida',
  templateUrl: './saida.component.html',
  styleUrls: ['./saida.component.scss'],
  animations: [fadeAnimation]
})
export class SaidaComponent implements OnInit {
  @ViewChild('input', { static: true }) input: ElementRef;
  public title: string;
  public dataSource: MatTableDataSource<Dac>;
  public saidaForm: FormGroup;
  public editDacForm: FormGroup;
  public addDac: boolean = true;
  public editCdaId: number = undefined;
  public dac: Dac[] = [];
  public clientes = new Array<Cliente>();
  public filteredClientes: Observable<Array<Cliente>>;
  public mandatarios = new Array<Mandatario>();
  public filteredMandatarios: Observable<Array<Cliente>>;
  public compradores = new Array<Comprador>();
  public filteredCompradores: Observable<Array<Cliente>>;
  public inputDataObj = {
    id_cda: 0,
  }
  public id_cda = 0;

  constructor(
    public dialogRef: MatDialogRef<SaidaComponent>,
    private dacService: DacService,
    private clienteService: ClientesService,
    private mandatarioService: MandatariosService,
    private compradorService: CompradoresService,
    private formBuilder: FormBuilder,
    private alert: CustomAlertService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = data.title;
    this.id_cda = data.id_cda;
    this.saidaForm = this.formBuilder.group({
      id_cdaSaida: new FormControl(''),
      tipoOperacao: new FormControl(''),
      tipoSaida: new FormControl(''),
      local: new FormControl(''),
      numeroSaida: new FormControl(''),
      dataSaida: new FormControl(''),
      numeroNE: new FormControl(''),
      dataNE: new FormControl(''),
      numeroDTT: new FormControl(''),
      dataDTT: new FormControl(''),
      quantidade: new FormControl(0),
      pesoBruto: new FormControl(0),
      pesoLiquido: new FormControl(0),
      valorVenda: new FormControl(0),
      nomeCliente: new FormControl('', Validators.required),
      nomeMandatario: new FormControl('', Validators.required),
      nomeComprador: new FormControl('', Validators.required),
      id_clienteVendedor: new FormControl(0),
      id_mandatario: new FormControl(0),
      id_comprador: new FormControl(0),
      numeroConhecimentoCarga: new FormControl(''),
      saldoMercadoria: new FormControl(0),
      valorSaldo: new FormControl(0)
    });
  }

  ngOnInit() {

    this.preencheTabelas().then(() => {

      this.dacService.getSaida(this.id_cda.toString()).then(res => {

        if (res.length > 0) {

          this.saidaForm.setValue({
            "id_cdaSaida": res[0].id_cdaSaida,
            "tipoOperacao": res[0].tipoOperacao,
            "tipoSaida": res[0].tipoSaida,
            "local": res[0].local,
            "quantidade": res[0].quantidade,
            "pesoBruto": res[0].pesoBruto,
            "pesoLiquido": res[0].pesoLiquido,
            "valorVenda": res[0].valor,
            "numeroSaida": res[0].numeroSaida,
            "dataSaida": new Date(res[0].dataSaida).toLocaleDateString('pt-BR'),
            "numeroNE": res[0].numeroNE,
            "dataNE": new Date(res[0].dataNE).toLocaleDateString('pt-BR'),
            "numeroDTT": res[0].numeroDTT,
            "dataDTT": new Date(res[0].dataDTT).toLocaleDateString('pt-BR'),
            "numeroConhecimentoCarga": res[0].numeroConhecimentoCarga,
            "saldoMercadoria": res[0].saldoMercadoria,
            "valorSaldo": res[0].valorSaldo,
            "id_clienteVendedor": res[0].id_clienteVendedor,
            "id_mandatario": res[0].id_mandatario,
            "id_comprador": res[0].id_comprador,
            "nomeCliente": res[0].id_clienteVendedor ? this.clientes.find(el => el.id_cliente == res[0].id_clienteVendedor) : "",
            "nomeMandatario": res[0].id_mandatario ? this.mandatarios.find(el => el.id_mandatario == res[0].id_mandatario) : "",
            "nomeComprador": res[0].id_comprador ? this.compradores.find(el => el.id_comprador == res[0].id_comprador) : ""
          })
        }
        else this.addDac = true;

      });

    })

  }

  preencheTabelas() {
    return new Promise(async (resolve, reject) => {
      await this.clienteService.getClientes().then(clientes => {
        this.clientes = clientes;
        this.filteredClientes = this.saidaForm.controls.nomeCliente.valueChanges
          .pipe(
            startWith(''),
            filter(value => value !== null),
            map(value => {
              if (value.nome) {
                return this.clientes.filter(c => c.nome.toLowerCase().includes(value.nome.toLowerCase()));
              } else {
                return this.clientes.filter(c => c.nome.toLowerCase().includes(value.toLowerCase()));
              }
            })
          );
      });
      await this.mandatarioService.getMandatarios().then(mandatarios => {
        this.mandatarios = mandatarios;
        this.filteredMandatarios = this.saidaForm.controls.nomeMandatario.valueChanges
          .pipe(
            startWith(''),
            filter(value => value !== null),
            map(value => {
              if (value.nome) {
                return this.mandatarios.filter(c => c.nome.toLowerCase().includes(value.nome.toLowerCase()));
              } else {
                return this.mandatarios.filter(c => c.nome.toLowerCase().includes(value.toLowerCase()));
              }
            })
          );
      });
      await this.compradorService.getCompradores().then(compradores => {
        this.compradores = compradores;
        console.log(compradores)
        this.filteredCompradores = this.saidaForm.controls.nomeComprador.valueChanges
          .pipe(
            startWith(''),
            filter(value => value !== null),
            map(value => {
              if (value.nome) {
                return this.compradores.filter(c => c.nome.toLowerCase().includes(value.nome.toLowerCase()));
              } else {
                return this.compradores.filter(c => c.nome.toLowerCase().includes(value.toLowerCase()));
              }
            })
          );
      });

      resolve(true);

    })
  }

  nomeClienteDisplay = (cliente?: Cliente): string | undefined => {

    if (cliente) {
      if (this.addDac) {
        this.saidaForm.patchValue({
          "id_clienteVendedor": cliente.id_cliente
        })
      }
      // else {
      //   this.editDacForm.patchValue({
      //     "id_clienteVendedor": cliente.id_cliente
      //   })
      // }
      return cliente.nome
    }
    else {
      return undefined;
    }
  }
  nomeMandatarioDisplay = (mandatario?: Mandatario): string | undefined => {
    if (mandatario) {
      if (this.addDac) {
        this.saidaForm.patchValue({
          "id_mandatario": mandatario.id_mandatario
        })
      }
      // else {
      //   this.editDacForm.patchValue({
      //     "id_mandatario": mandatario.id_mandatario
      //   })
      // }
      return mandatario.nome
    }
    else {
      return undefined;
    }
  }
  nomeCompradorDisplay = (comprador?: Comprador): string | undefined => {
    if (comprador) {
      if (this.addDac) {
        this.saidaForm.patchValue({
          "id_comprador": comprador.id_comprador
        })
      }
      // else {
      //   this.editDacForm.patchValue({
      //     "id_comprador": comprador.id_comprador
      //   })
      // }
      return comprador.nome
    }
    else {
      return undefined;
    }
  }

  newSaida() {
    let dac = new Saida();
    dac.tipoOperacao = this.saidaForm.value.tipoOperacao;
    dac.tipoSaida = this.saidaForm.value.tipoSaida;
    dac.local = this.saidaForm.value.local;
    dac.numeroSaida = this.saidaForm.value.numeroSaida;
    dac.dataSaida = this.saidaForm.value.dataSaida;
    dac.numeroNE = this.saidaForm.value.numeroNE;
    dac.dataNE = this.saidaForm.value.dataNE;
    dac.numeroDTT = this.saidaForm.value.numeroDTT;
    dac.dataDTT = this.saidaForm.value.dataDTT;
    dac.quantidade = this.saidaForm.value.quantidade;
    dac.pesoBruto = this.saidaForm.value.pesoBruto;
    dac.pesoLiquido = this.saidaForm.value.pesoLiquido;
    dac.valorVenda = this.saidaForm.value.valorVenda;
    dac.id_clienteVendedor = this.saidaForm.value.id_clienteVendedor;
    dac.id_mandatario = this.saidaForm.value.id_mandatario;
    dac.id_comprador = this.saidaForm.value.id_comprador;
    dac.numeroConhecimentoCarga = this.saidaForm.value.numeroConhecimentoCarga;
    dac.saldoMercadoria = this.saidaForm.value.saldoMercadoria;
    dac.valorSaldo = this.saidaForm.value.valorSaldo;

    dac.id_cda = this.id_cda;

    if (this.saidaForm.value.id_cdaSaida > 0) {
      dac.id_cdaSaida = this.saidaForm.value.id_cdaSaida;

      this.dacService.editSaida(dac).then(res => {
        console.log(res)
        this.alert.overflowIconSuccessAlert({
          title: "ALERT.SUCESSO",
          text: "A sua solicitação foi efetuada com sucesso."
        });
        this.dialogRef.close()
      })
        .catch(err => {
          console.log(err);
          this.alert.overflowIconErrorAlert({
            title: "ALERT.ERRO",
            text: "Algo deu errado e sua operação não foi concluída. \n" + err.error.message
          });
        })
    }
    else {
      this.dacService.addSaida(dac).then(res => {
        console.log(res)
        this.alert.overflowIconSuccessAlert({
          title: "ALERT.SUCESSO",
          text: "A sua solicitação foi efetuada com sucesso."
        });
        this.dialogRef.close()
      })
        .catch(err => {
          console.log(err);
          this.alert.overflowIconErrorAlert({
            title: "ALERT.ERRO",
            text: "Algo deu errado e sua operação não foi concluída. \n" + err.error.message
          });
        })

    }
  }

  compareCategoryObjects(object1: any, object2: any) {
    return object1 && object2 && object1.id == object2.id;
  }

}
