import { ComplementoPontoDeControleComponent } from 'src/app/components/complemento-ponto-de-controle/complemento-ponto-de-controle.component';
import { FileUploadTemplateComponent } from 'src/app/components/file-upload-template/file-upload-template.component';
import { AlertTemplateComponent } from 'src/app/components/alert-template/alert-template.component';
import { AlertController } from '@ionic/angular';
import { MatDialog } from '@angular/material/dialog';
// import { _getOptionScrollPosition } from '@angular/material/md-option';
import { Injectable } from '@angular/core';
import { ControleMedicaoComponent } from 'src/app/components/controle-medicao/controle-medicao.component';
import { CheckLotesComponent } from 'src/app/components/check-lotes/check-lotes.component';
import { OperacaoResolver } from 'src/app/pages/operacoes/operacoes.resolver';
import { OperacaoService, OperacaoRequest } from '../operacoes/operacao/operacao.service';
import { EditOperacaoComponent } from 'src/app/components/edit-operacao/edit-operacao.component';
import { PlantasService } from '../tabelas/plantas/plantas.service';
import { OperacaoEvento } from '../operacoes/operacao-evento/operacao-evento.service';
import { OperacaoVazaoService } from '../operacoes/operacao-vazao/operacao-vazao.service';
import { InterrupcaoComponent } from 'src/app/components/interrupcao/interrupcao.component';
import { data } from 'jquery';
import { ConfirmaPontoControleManualComponent } from 'src/app/components/confirma-ponto-controle-manual/confirma-ponto-controle-manual.component';
import { PraticoSelectComponent } from 'src/app/components/pratico-select-modal/pratico-select.component';
import { CaladoSaidaComponent } from 'src/app/components/calado-saida/calado-saida.component';
@Injectable({
  providedIn: 'root'
})
export class CustomAlertService {

  constructor(
    private alertCtrl: AlertController,
    public dialog: MatDialog
  ) { }

  // async standardAlert(title: string, message: string, type: string = "standard") {
  //   let alert = await this.alertCtrl.create({
  //     header: title,
  //     subHeader: message,
  //     cssClass: `${type} custom-alert`,
  //     buttons: [
  //       {
  //         text: 'Entendi',
  //         role: 'cancel',
  //         handler: data => {
  //           console.log('Cancel clicked');
  //         }
  //       },
  //     ]
  //   });
  //   await alert.present();
  // }

  // async htmlAlert(title: string, message: string) {
  //   let alert = await this.alertCtrl.create({
  //     header: title,
  //     message: message,
  //     buttons: [
  //       {
  //         text: 'Entendi',
  //         role: 'cancel',
  //         handler: data => {
  //           console.log('Cancel clicked');
  //         }
  //       },
  //     ]
  //   });
  //   await alert.present();
  // }

  // async confirmationAlert(title: string, message: string, okFunction, cancelFunction, ) {
  //   let alert = await this.alertCtrl.create({
  //     header: title,
  //     subHeader: message,
  //     cssClass: 'custom-alert',
  //     buttons: [
  //       {
  //         text: 'Sim',
  //         handler: okFunction
  //       },
  //       {
  //         text: 'Não',
  //         role: 'cancel',
  //         handler: cancelFunction
  //       },
  //     ]
  //   });
  //   await alert.present();
  // }

  basicAlert(parans={title:'Thanks for sharing!',button:'Download'}): void {
    const dialogRef = this.dialog.open(AlertTemplateComponent, {
      data: {
        title: parans.title,
        button: parans.button
      },
      autoFocus: false
    });
  }

  textAlert(): void {
    const dialogRef = this.dialog.open(AlertTemplateComponent, {
      data: {
        title: 'Meet up with Alba',
        text: 'Today, 5:30 PM',
        button: 'Yasss!'
      },
      autoFocus: false
    });
  }

  iconAlert(): void {
    const dialogRef = this.dialog.open(AlertTemplateComponent, {
      data: {
        iconType: 'success',
        title: 'Good job!',
        text: 'You are the winner',
        button: 'Play again'
      },
      autoFocus: false
    });
  }

  overflowIconSuccessAlert(options: { title: string, text: string }): void {
    const dialogRef = this.dialog.open(AlertTemplateComponent, {
      data: {
        iconType: 'success',
        iconPosition: 'overflow',
        title: options.title,
        text: options.text,
        button: 'Ok'
      },
      autoFocus: false,
      panelClass: ['overflow-icon-alert-wrapper']
    });

  }

  overflowIconErrorAlert(options: { title: string, text: string, okFunc?:Function }): void {
    const dialogRef = this.dialog.open(AlertTemplateComponent, {
      data: {
        iconType: 'error',
        iconPosition: 'overflow',
        title: options.title,
        text: options.text,
        button: 'Ok',
      },
      autoFocus: false,
      panelClass: ['overflow-icon-alert-wrapper']
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result && typeof options.okFunc === 'function') {
        options.okFunc(result);
      }
    })
  }

  optionsAlert(options: {
    title: string,
    message: string,
    okFunction: Function
  }): void {
    const dialogRef = this.dialog.open(AlertTemplateComponent, {
      data: {
        iconType: 'success',
        iconPosition: 'overflow',
        title: options.title,
        text: options.message,
        options: true
      },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        options.okFunction(result)
        // this.dialog.open(AlertTemplateComponent, {
        //   data: {
        //     iconType: 'success',
        //     title: 'Congrats!',
        //     text: 'Your exam has been submitted successfully.',
        //     button: 'Ok'
        //   },
        //   autoFocus: false
        // });
      }
    });
  }

  canceledAlert(options: { okFunc: Function }): void {
    const dialogRef = this.dialog.open(AlertTemplateComponent, {
      data: {
        iconType: 'error',
        title: 'Do you want to delete your file?',
        text: 'You will not be able to recover it',
        options: true
      },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dialog.open(AlertTemplateComponent, {
          data: {
            iconType: 'success',
            title: 'Deleted',
            text: 'Your file has been deleted.',
            button: 'Ok'
          },
          autoFocus: false
        });
        options.okFunc(result)
      } else {
        this.dialog.open(AlertTemplateComponent, {
          data: {
            iconType: 'error',
            title: 'Canceled',
            text: 'Your file is safe. You can find it in your inbox.',
            button: 'Ok'
          },
          autoFocus: false
        });
      }
    });
  }

  inputAlert(options:
    {
      title: string,
      dataType: "text" | "number",
      okFunc: Function,
      lista?: any[],
      listaLabels?: string[],
      tipo?: string,
      listaInicial?: any[],
      hidePressao?: boolean,
      operacaoVazaoService?: OperacaoVazaoService,
      id_operacao?: number,
      id_operacaoLote?: number
    }): void {
    const dialogRef = this.dialog.open(AlertTemplateComponent,
      {
        data: {
          title: options.title,
          input: true,
          dataType: options.dataType,
          lista: options.lista,
          listaLabels: options.listaLabels,
          tipo: options.tipo,
          listaInicial: options.listaInicial,
          hidePressao: options.hidePressao,
          operacaoVazaoService: options.operacaoVazaoService,
          id_operacao: options.id_operacao,
          id_operacaoLote: options.id_operacaoLote
        },
        autoFocus: true
      });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        options.okFunc(result);
      }
    });
  }

  fileInputAlert(options: { title: string, text: string, okFunc: Function, tiposArquivo?: any[], arquivosExistentes?: any[], operacoesService?: any, utilService?: any, alert?: any, iab?: any }): void {
    const dialogRef = this.dialog.open(FileUploadTemplateComponent, {
      data: {
        title: options.title,
        text: options.text,
        input: true,
        tiposArquivo: options.tiposArquivo,
        arquivosExistentes: options.arquivosExistentes,
        operacoesService: options.operacoesService,
        utilService: options.utilService,
        alert: options.alert,
        iab: options.iab
      },
      autoFocus: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        options.okFunc(result);
      }
    });
  }

  alertControleMedicao(options: { title: string, okFunc: Function, tipo?: string, operacao?: any, operacoesService?: any, alert?: any, loader?: any }): void {
    const dialogRef = this.dialog.open(ControleMedicaoComponent, {
      data: {
        title: options.title,
        input: true,
        tipo: options.tipo,
        operacao: options.operacao,
        operacoesService: options.operacoesService,
        alert: options.alert,
        loader: options.loader
      },
      autoFocus: true,
      hasBackdrop: false
    })
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          options.okFunc(result)
        }
      })
  }

  alertInterrupcao(options: { title: string, okFunc: Function, tipo?: string, operacao?: any, paradasService?: any, alert?: any, loader?: any }): void {
    const dialogRef = this.dialog.open(InterrupcaoComponent, {
      data: {
        title: options.title,
        input: true,
        tipo: options.tipo,
        operacao: options.operacao,
        paradasService: options.paradasService,
        alert: options.alert,
        loader: options.loader
      },
      autoFocus: true,
      hasBackdrop: false
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      if (result) {
        options.okFunc(result);
      }
    });
  }

  timedAlert(): void {
    const dialogRef = this.dialog.open(AlertTemplateComponent, {
      data: {
        title: 'Auto close alert!',
        text: 'I will close in 2 seconds. Bye bye!',
        time: 2000
      },
      autoFocus: false
    });
  }

  alertCheckComplemento(options: { okFunc: Function, service: any, operacao: any, tipo: string }): void {
    console.log(options.operacao);
    
    const dialogRef = this.dialog.open(ComplementoPontoDeControleComponent, {
      data: {
        input: true,
        service: options.service,
        operacao: options.operacao,
        tipo: options.tipo        
      },
      autoFocus: true,
      disableClose: true,
      hasBackdrop: false
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        options.okFunc(result)
      }
    })
  }

  praticoComponent(options){
    const dialogRef = this.dialog.open(PraticoSelectComponent, {
      data: {
        input: true,
        title: options.title,
        praticos: options.praticos || options.rebocadores,
        service: options.service
        // operacao: options.operacao,
        // tipo: options.tipo        
      },
      autoFocus: true,
      disableClose: true,
      hasBackdrop: false
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        options.okFunc(result);
      }
    });
  }

  alertCheckLotes(options: { okFunc: Function, title: string, service: any, operacao: any, tipo?: string, type?: boolean }): void {
    const dialogRef = this.dialog.open(CheckLotesComponent, {
      data: {
        title: options.title,
        input: true,
        operacao: options.operacao,
        tipo: options.tipo,
        type: options.type
      },
      autoFocus: true,
      disableClose: true,
      hasBackdrop: false
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        options.okFunc(result);
      }
    });
  }

  alertEditOperacao(options: {
    title: string,
    okFunc: Function,
    tipo: string,
    type?: any,
    operacao: any,
    operacoesService?: any,
    plantasService?: any,
    naviosService?: any,
    bercosService?: any,
    terminaisService?: any,
    clienteService?: any,
    operacaoRequest?: OperacaoRequest,
    loader?: any,
    operacaoEventoService?: any,
    evento?: OperacaoEvento,
    proximoEvento?: OperacaoEvento;
    operacaoVazaoService?: OperacaoVazaoService,
    isTotal: boolean,
    alert?: any,
    id_operacao?: number,
    id_lote?: number
  }): void {
    const dialogRef = this.dialog.open(EditOperacaoComponent, {
      data: {
        title: options.title,
        input: true,
        tipo: options.tipo,
        type: options.type,
        operacao: options.operacao,
        operacoesService: options.operacoesService,
        plantasService: options.plantasService,
        naviosService: options.naviosService,
        bercosService: options.bercosService,
        terminaisService: options.terminaisService,
        clienteService: options.clienteService,
        operacaoRequest: options.operacaoRequest,
        loader: options.loader,
        isTotal: options.isTotal,
        operacaoEventoService: options.operacaoEventoService,
        operacaoVazaoService: options.operacaoVazaoService,
        evento: options.evento,
        proximoEvento: options.proximoEvento,
        alert: options.alert,
        id_operacao: options.id_operacao,
        id_lote: options.id_lote
      },
      autoFocus: true,
      disableClose: true,
      hasBackdrop: false

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        options.okFunc(result);
      }
    });
  }

  alertConfirmaOperacaoManual(options: {
    
    title: string,
    okFunc: Function,
    //  operacoesService?: any,
    // operacaoRequest?: OperacaoRequest,
  }): void {
    const dialogRef = this.dialog.open(ConfirmaPontoControleManualComponent, {
      data: {
        title: options.title,
        input: true
      },
      autoFocus: true,
      disableClose: true,
      hasBackdrop: false

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        options.okFunc(result);
      }
    });
  }

  alertCaladoSaida(options: {
    
    title: string,
    caladoSaidaValue,
    okFunc: Function,
    cancelFunc: Function,
    //  operacoesService?: any,
    // operacaoRequest?: OperacaoRequest,
  }): void {
    const dialogRef = this.dialog.open(CaladoSaidaComponent, {
      data: {
        title: options.title,
        caladoSaidaValue: options.caladoSaidaValue
      },
      autoFocus: true,
      disableClose: true,
      hasBackdrop: false

    });
    dialogRef.afterClosed().subscribe(result => {
      if (!result.canceled) {
        options.okFunc(result);
      }
      if(result.canceled){
        options.cancelFunc(result);
      }
    });
  }

}

