import { LoadingController } from '@ionic/angular';
import { Injectable, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root'
})
export class CustomLoaderService {

  // public loader: any;
  public isLoading = false;

  constructor(
    public loadingCtrl: LoadingController,
    private ngxService: NgxUiLoaderService
    ) {

    // this.loader = this.loadingCtrl.create({
    //   cssClass: 'custom-loader',
    //   spinner: null,
    // });

  }

  public async dismiss() {
    this.ngxService.stop();
    // this.isLoading = false;
    // return await this.loadingCtrl.dismiss().then(() => console.log('dismissed')).catch();
  }
  
  public async show() {
    this.ngxService.start();
    // this.isLoading = true;
    // return await this.loadingCtrl.create({
    //   cssClass: 'custom-loader',
    //   spinner: null,
    //   backdropDismiss: true,
    //   // duration: 5000,
    // }).then(a => {
    //   a.present().then(() => {
    //     if (!this.isLoading) {
    //       a.dismiss().then(() => console.log('abort presenting'));
    //     }
    //   });
    // });
  }


  // public async dismiss2() {
  //   return  
  // }
  // public async dismiss2() {
  // this.loader = this.loadingCtrl.create({
  //   cssClass: 'custom-loader',
  //   spinner: null,
  // });
  // return  

}

