import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss'],
})
export class LanguagesComponent implements OnInit {

  @ViewChild('langs-container', { static: false }) langsContainer: ElementRef;

  private subscription: Subscription;

  constructor(
    public translate: TranslateService,
    private storage: Storage,

  ) { }

  ngAfterContentInit() {
    document.getElementById(this.translate.currentLang).classList.toggle("lang-selected");
  }

  ngOnInit() {

    this.subscription = this.translate.onLangChange.subscribe((lang) => {

      this.storage.set("LANG", lang.lang);

      document.querySelectorAll("b").forEach(element => {
        element.classList.remove("lang-selected")
      });
      document.getElementById(lang.lang).classList.toggle("lang-selected")

    });
  }

}
