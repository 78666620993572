import { SecurePipe } from './../pipes/secure.pipe';
import { FileUploadTemplateComponent } from './file-upload-template/file-upload-template.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { RouterModule } from '@angular/router';
import { InvalidInputComponent } from './invalid-input/invalid-input.component';
import { ThemeToggleComponent } from './theme-toggle/theme-toggle.component';
import { LanguagesComponent } from './languages/languages.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SideBarComponent } from './side-bar/side-bar.component';
import { SuperTabsModule } from '@ionic-super-tabs/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrMaskerModule } from 'br-mask';


import { NgxMaskModule, IConfig } from 'ngx-mask';
export var options: Partial<IConfig> | (() => Partial<IConfig>) = {};


/*
=================== Imports do Template Fully ===================
*/
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
// Material Navigation
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
// Material Layout
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTreeModule } from '@angular/material/tree';
// Material Buttons & Indicators
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRippleModule } from '@angular/material/core';
// Material Popups & Modals
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
// Material Data tables
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatNativeDateModule } from '@angular/material/core';

import {
    NgbButtonsModule,
    NgbProgressbarModule,
    NgbDatepickerModule,
    NgbRatingModule,
    NgbTimepickerModule,
    NgbPopoverModule,
    NgbCarouselModule,
    // NgbTabsetModule,
    NgbNavModule,
    NgbTypeaheadModule,
    NgbDropdownModule,
} from '@ng-bootstrap/ng-bootstrap';

import { ShellModule } from '../shell/shell.module';
//   import { FileUploaderDirective } from './file-uploader/file-uploader.directive';
//   import { ShowHidePasswordComponent } from './show-hide-password/show-hide-password.component';
//   import { ShowHideInputDirective } from './show-hide-password/show-hide-input.directive';
import { AlertTemplateComponent } from './alert-template/alert-template.component';
import { NotificationTemplateComponent } from './notification-template/notification-template.component';
import { SignInModalComponent } from './sign-in-modal/sign-in-modal.component';
import { ClockComponent } from './clock/clock.component';
import { ForecastComponent } from './forecast/forecast.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { FileUploaderDirective } from './file-uploader/file-uploader.directive';
import { PraticoSelectComponent } from './pratico-select-modal/pratico-select.component';
import { ComplementoPontoDeControleComponent } from './complemento-ponto-de-controle/complemento-ponto-de-controle.component';
import { ControleMedicaoComponent } from './controle-medicao/controle-medicao.component';

/*
=================================================================
*/
// CookieService
import { CookieService } from 'ngx-cookie-service';
import { CheckLotesComponent } from './check-lotes/check-lotes.component';
import { EditOperacaoComponent } from './edit-operacao/edit-operacao.component';
import { InterrupcaoComponent } from './interrupcao/interrupcao.component';
import { ConfirmaPontoControleManualComponent } from './confirma-ponto-controle-manual/confirma-ponto-controle-manual.component';
import { SaidaComponent } from './saida/saida.component';
import { GraficoOperacoesConluidasComponent } from './grafico-operecaoes-concluidas/grafico-operecaoes-concluidas.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CaladoSaidaComponent } from './calado-saida/calado-saida.component';

@NgModule({
    imports: [
        NgxEchartsModule,
        NgxChartsModule,
        // NgModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatTableModule,
        CommonModule,
        FormsModule,
        IonicModule,
        SuperTabsModule,
        BrMaskerModule,
        TranslateModule,
        ShellModule,
        MatIconModule,
        MatDialogModule,
        MatSnackBarModule,
        MatExpansionModule,
        MatSelectModule,
        MatFormFieldModule,
        RouterModule,
        NgbTimepickerModule,
        NgbDatepickerModule,
        NgbProgressbarModule,
        NgxMaskModule.forRoot(options),
    ],
    exports: [
        SecurePipe,
        InvalidInputComponent,
        BreadcrumbComponent,
        TranslateModule,
        SideBarComponent,
        SideMenuComponent,
        LanguagesComponent,
        ThemeToggleComponent,
        BrMaskerModule,
        // Re-export these modules to prevent repeated imports (see: https://angular.io/guide/ngmodule#re-exporting-other-modules)
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        // HttpClientModule,
        ShellModule,
        // RouterModule,
        // FileUploaderDirective,
        // ShowHidePasswordComponent,
        // ShowHideInputDirective,
        ComplementoPontoDeControleComponent,
        PraticoSelectComponent,
        CheckLotesComponent,
        EditOperacaoComponent,
        ConfirmaPontoControleManualComponent,
        CaladoSaidaComponent,
        ControleMedicaoComponent,
        InterrupcaoComponent,
        AlertTemplateComponent,
        FileUploadTemplateComponent,
        NotificationTemplateComponent,
        SignInModalComponent,
        // angular material modules
        MatExpansionModule,
        MatTableModule,
        MatTabsModule,
        MatPaginatorModule,
        MatSortModule,
        MatToolbarModule,
        MatMenuModule,
        MatButtonModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatProgressBarModule,
        MatCheckboxModule,
        MatInputModule,
        MatNativeDateModule,
        MatChipsModule,
        MatSelectModule,
        MatStepperModule,
        MatTooltipModule,
        MatAutocompleteModule,
        MatButtonToggleModule,
        MatSlideToggleModule,
        MatDialogModule,
        MatSnackBarModule,
        MatSliderModule,
        MatIconModule,  // needed to use the MatIconRegistry to register our own icons
        // ng bootstrap modules
        NgbButtonsModule,
        NgbProgressbarModule,
        NgbDatepickerModule,
        NgbRatingModule,
        NgbTimepickerModule,
        NgbPopoverModule,
        NgbCarouselModule,
        // NgbTabsetModule, buscar depois
        NgbNavModule,
        NgbTypeaheadModule,
        NgbDropdownModule,
        ClockComponent,
        ForecastComponent,
        PageHeaderComponent,
        FileUploaderDirective,
        NgxMaskModule,
        SaidaComponent,
        GraficoOperacoesConluidasComponent
    ],
    declarations: [
        SideBarComponent,
        SecurePipe,

        SideMenuComponent,
        LanguagesComponent,
        ThemeToggleComponent,
        InvalidInputComponent,
        BreadcrumbComponent,
        // FileUploaderDirective,
        // ShowHidePasswordComponent,
        // ShowHideInputDirective,
        ComplementoPontoDeControleComponent,
        PraticoSelectComponent,
        CheckLotesComponent,
        EditOperacaoComponent,
        ConfirmaPontoControleManualComponent,
        CaladoSaidaComponent,
        ControleMedicaoComponent,
        InterrupcaoComponent,
        AlertTemplateComponent,
        FileUploadTemplateComponent,
        NotificationTemplateComponent,
        SignInModalComponent,
        ClockComponent,
        ForecastComponent,
        PageHeaderComponent,
        FileUploaderDirective,
        SaidaComponent,
        GraficoOperacoesConluidasComponent
    ],
    entryComponents: [
        ComplementoPontoDeControleComponent,
        PraticoSelectComponent,
        CheckLotesComponent,
        EditOperacaoComponent,
        ConfirmaPontoControleManualComponent,
        CaladoSaidaComponent,
        ControleMedicaoComponent,
        InterrupcaoComponent,
        AlertTemplateComponent,
        FileUploadTemplateComponent,
        NotificationTemplateComponent,
        SignInModalComponent,
        SaidaComponent
    ],
    providers: [
        CookieService,
        SecurePipe
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]

})

export class CustomComponentModule { }
