import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-invalid-input',
  templateUrl: './invalid-input.component.html',
  styleUrls: ['./invalid-input.component.scss'],
})

export class InvalidInputComponent implements OnInit {
  @Input('element') element: any = null;
  @Input('text') textMessage: string = 'Campo obrigatório';
  public hashId: string = '';

  constructor() {
    this.hashId = "invalidInput" + this.getRandomInt(1, 100);
  }

  ngOnInit() {
    setTimeout(() => {
      // console.log(this.element)
      if (this.element) {
        this.element.el.parentElement.style.marginBottom = '0'
      }
      else {
        document.getElementById(this.hashId).parentElement.previousElementSibling["style"].marginBottom = '0'
      }

      var mutationObserver = new MutationObserver((mutations) => {

        mutations.forEach((mutation) => {

          if (mutation.target["classList"].contains("ion-touched") && mutation.target["classList"].contains("ion-invalid")) {
            document.getElementById(this.hashId).style.height = '1.8em'
            document.getElementById(this.hashId).style.opacity = '1'
          }
          else {
            document.getElementById(this.hashId).style.height = '0'
            document.getElementById(this.hashId).style.opacity = '0'
          }

        });

      });
      mutationObserver.observe(document.getElementById(this.hashId).parentElement.previousElementSibling, {
        characterDataOldValue: true,
        attributeOldValue: true,
        characterData: true,
        attributes: true,
        childList: true,
        subtree: true,
      });
    }, 200);
  }

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

}
