import { OperacaoVazaoService } from 'src/app/services/operacoes/operacao-vazao/operacao-vazao.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import moment from "moment";

@Component({
  selector: 'app-alert-template-component',
  templateUrl: 'alert-template.component.html',
  styleUrls: ['./alert-template.component.scss']
})

export class AlertTemplateComponent implements OnInit {

  public iconType: string;
  public iconPosition: string;
  public title: string;
  public text: string;
  public options: false;
  public input: false;
  public dataType: string;
  public button: string;
  public inputData: string;
  public inputDataObj = {
    hora: '',
    volume: '',
    pressao: '',
    pressaoManifold: '',
    destinoVolumeM3: '',
    destinoPressao: '',
  }

  private strippingTimeNow = {
    begin: '',
    end: ''
  }

  public stripping = {
    begin: '',
    end: ''
  }

  private cowTimeNow = {
    begin: '',
    end: ''
  }

  public cowTime = {
    begin: '',
    end: ''
  }

  public tipo: string;

  public lista: any[];
  public listaLabels: string[];
  public listaInicial: any[];

  public operacaoVazaoService: any
  public obs: any

  public id_operacao:number = 0;
  public id_operacaoLote:number = 0;

  constructor(
    public dialogRef: MatDialogRef<AlertTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog
  ) {
    
    this.iconType = data.iconType;
    this.iconPosition = data.iconPosition ? data.iconPosition : 'default';
    this.title = data.title;
    this.text = data.text;
    this.options = data.options;
    this.input = data.input;
    this.button = data.button;
    this.dataType = data.dataType;
    this.lista = data.lista;
    this.listaLabels = data.listaLabels;
    this.tipo = data.tipo;
    this.listaInicial = data.listaInicial;
    this.operacaoVazaoService = data.operacaoVazaoService;
    this.inputData = data.inputData;
    this.obs = data.obs;
    this.id_operacao = data.id_operacao ? data.id_operacao : 0;
    this.id_operacaoLote = data.id_operacaoLote ? data.id_operacaoLote : 0;
    try {
      console.log(data);
      
      this.strippingTimeNow.begin = this.getStrippingTime(data.lista[0].strippingInicio);
      this.strippingTimeNow.end = this.getStrippingTime(data.lista[0].strippingFim);

      this.cowTimeNow.begin = this.getStrippingTime(data.lista[0].cowTimeInicio);
      this.cowTimeNow.end = this.getStrippingTime(data.lista[0].cowTimeFim);

      this.stripping = {...this.strippingTimeNow};
      this.cowTime = {...this.cowTimeNow};
      
    }
    catch(err){
      // console.error(err)
    }
    // console.log(this.data)
    if (data.time) {
      setTimeout(() => {
        this.dialogRef.close();
      }, data.time);
    }
  }

  ngOnInit() {

    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === "Escape") {
        this.dialogRef.close();
      }
    });

    this.dialogRef.backdropClick().subscribe(event => {
      this.dialogRef.close();
    });

    if (this.lista && this.lista.length > 0) {
      let ultimaHora = this.lista[this.lista.length - 1].hora;
      if (ultimaHora.split(':')[0] == 23) {
        this.inputDataObj.hora = `00:${ultimaHora.split(':')[1]}`;
      }
      else {
        this.inputDataObj.hora = `${(parseInt(ultimaHora.split(':')[0]) + 1).toString().padStart(2, '0')}:${ultimaHora.split(':')[1]}`;
      }
    }
    else if (this.listaInicial && this.listaInicial.length > 0) {
      let now = moment(new Date()).format('HH:mm');
      this.inputDataObj.hora = now;
      this.inputDataObj.volume = this.listaInicial[0].volumeM3.toString() ? this.listaInicial[0].volumeM3.toString() : '0';
      this.inputDataObj.pressaoManifold = '0';
    }
  }

  onChange(selectedChange) {
    console.log(selectedChange);
  }

  async editVazao(item) {

    console.log(item);

    await this.operacaoVazaoService.editOperacaoVazao(item)
      .then(res => {
        console.log(res)
        // this.alert.overflowIconSuccessAlert({
        //   title: "Sucesso",
        //   text: "Horário atualizado!"
        // });
      }).catch(err => {
        console.log(err)
        // this.alert.overflowIconErrorAlert({
        //   title: "ALERT.ATENCAO",
        //   text: err.error.message
        // });
      });

  }

  async apagarVazao(item) {

    console.log(item);

    const dialogRef = this.dialog.open(AlertTemplateComponent, {
      data: {
        iconType: 'success',
        iconPosition: 'overflow',
        title: "ALERT.ATENCAO",
        text: "ALERT.VAZAO.MSG_EXCLUIR",
        options: true
      },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await this.operacaoVazaoService.deleteOperacaoVazao(item)
          .then(res => {
            this.dialog.open(AlertTemplateComponent, {
              data: {
                iconType: 'success',
                iconPosition: 'overflow',
                title: 'ALERT.SUCESSO',
                text: 'ALERT.VAZAO.MSG_EXCLUIDO',
                button: 'Ok'
              },
              autoFocus: false
            });
            this.dialogRef.close();
          }).catch(err => {
            console.log(err)
          });
      }
    });

  }

  async addObservacao(item) {
    console.log(item);

    const dialogRef = this.dialog.open(AlertTemplateComponent, {
      data: {
        title: "ALERT.VAZAO.OBSERVACAO",
        input: true,
        dataType: "text-expand",
        inputData: item.obs
      },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        item.obs = result;
        this.operacaoVazaoService.addOperacaoObs(item).then(res => {
          console.log(res)
        }).catch(err => {
          console.log(err)
        });
        console.log(result);
      }
    });
  }

  validaStrippingTime(){
    if(this.stripping.begin && this.stripping.begin.length<5) return false
    if(this.stripping.end && this.stripping.end.length<5) return false
    return (this.stripping.begin!=this.strippingTimeNow.begin || this.stripping.end!=this.strippingTimeNow.end)
  }

  validaCowTime(){
    if(this.cowTime.begin && this.cowTime.begin.length<5) return false
    if(this.cowTime.end && this.cowTime.end.length<5) return false
    return (this.cowTime.begin!=this.cowTimeNow.begin || this.cowTime.end!=this.cowTimeNow.end)
  }

  saveStrippingTime(){
    this.operacaoVazaoService.saveStrippingTime(this.id_operacao,this.id_operacaoLote,this.stripping)
      .then(res => {
        this.strippingTimeNow = {...this.stripping}
      })
      .catch(err => {
        this.stripping = {...this.strippingTimeNow};        
        console.error(err)
      })
  }

  saveCowTime(){
    this.operacaoVazaoService.saveCowTime(this.id_operacao,this.id_operacaoLote,this.cowTime)
      .then(res => {
        this.cowTimeNow = {...this.cowTime};
      })
      .catch(err => {
        this.cowTime = {...this.cowTimeNow};        
        console.error(err)
      })
  }

  getStrippingTime(date){
    if(date){
      const time = new Date(date);
      return `${time.getHours()+3}:${time.getMinutes()}`
    }
    else {
      return ''
    }
  }

  disableSaveButton() {
    if (this.data.hidePressao) {
      return !(this.inputDataObj.hora.length > 0 && this.inputDataObj.volume.length > 0 && this.inputDataObj.destinoVolumeM3.length > 0)
    }
    else {
      return !(this.inputDataObj.hora.length > 0 && this.inputDataObj.pressao.length > 0 && this.inputDataObj.volume.length > 0 && this.inputDataObj.pressaoManifold.length > 0 && this.inputDataObj.destinoPressao.length > 0 && this.inputDataObj.destinoVolumeM3.length > 0)
    }
  }
}
