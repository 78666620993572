import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { Observable, throwError, from } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { CustomAlertService } from '../services/custom-alert/custom-alert.service';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomLoaderService } from '../services/custom-loader/custom-loader.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(
        private authService: AuthenticationService,
        public customAlert: CustomAlertService,
        private customLoader: CustomLoaderService,
        private router: Router,
    ) { }
    //COOKIE

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!(request.url.indexOf("/api/menu") > 0)) {
            this.customLoader.show();
        }
        if (environment.cookie) {
            const clone = request.clone({
                headers: request.headers.set('Content-Type', 'application/json'),
                withCredentials: environment.cookie
            })
            return next.handle(clone)
                .pipe(
                    catchError(response => {
                        if (response instanceof HttpErrorResponse) {
                            if (response.status === 403) {
                                this.customAlert.overflowIconErrorAlert({
                                    title: "ALERT.ATENCAO",
                                    text: "Token inválido!!!",
                                    okFunc: (data) => {
                                        this.authService.logout()
                                    }
                                });
                            }
                        }
                        return throwError(response);
                    })
                )
        }
        else {
            return from(this.authService.getToken())
                .pipe(
                    switchMap(token => {
                        const headers = request.headers
                            .set('Authorization', 'Bearer ' + token)
                        const requestClone = request.clone({
                            headers
                        })
                        this.customLoader.dismiss()
                        return next.handle(requestClone);
                    })
                );
        }




    }

}
