import { AuthenticationService } from '../../authentication/authentication.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class CompradoresService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
  ) { }

  async getCompradores(): Promise<any> {
    const user: any = await this.authService.getUserByLogin(this.authService);
    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.API_ENDPOINT.apiCompradores)
        .toPromise()
        .then(res => {console.log(res); resolve(res)})
        .catch(err => reject(err));
    })
  }

  async addCompradores(comprador: Comprador): Promise<any> {
    const user: any = await this.authService.getUserByLogin(this.authService);
    comprador.id_usuario = user.idUsuario;
    comprador.id_empresa = user.idEmpresa;
    console.log(comprador);

    return new Promise((resolve, reject) => {
      this.httpClient.post(environment.API_ENDPOINT.apiCompradores, comprador)
        .toPromise()
        .then(res => resolve(res))
        .catch(err => reject(err));
    })
  }

  async editCompradores(comprador: Comprador): Promise<any> {
    const user: any = await this.authService.getUserByLogin(this.authService);
    comprador.id_usuario = user.idUsuario;
    comprador.id_empresa = user.idEmpresa;
    console.log(comprador);

    return new Promise((resolve, reject) => {
      this.httpClient.put(environment.API_ENDPOINT.apiCompradores, comprador)
        .toPromise()
        .then(res => resolve(res))
        .catch(err => reject(err));
    })
  }

  async delCompradores(comprador: Comprador): Promise<any> {
    const user: any = await this.authService.getUserByLogin(this.authService);
    comprador.id_usuario = user.idUsuario;
    comprador.id_empresa = user.idEmpresa;
    console.log(comprador);

    return new Promise((resolve, reject) => {
      this.httpClient.delete(environment.API_ENDPOINT.apiCompradores, {
        params: {
          "id_empresa": user.idEmpresa.toString(),
          "id_usuario": user.idUsuario.toString(),
          "id_comprador": comprador.id_comprador.toString(),
        }
      })
        .toPromise()
        .then(res => resolve(res))
        .catch(err => reject(err));
    })
  }

}

@Injectable()
export class Comprador {
  id_usuario: number = 0;
  id_empresa: number = 0;
  id_comprador: number;
  nome: string;
  documento: string;
  endereco: string;
  numero: string;
  complemento: string;
  bairro: string;
  cidade: string;
  estado: string;
  cep: string;
}
