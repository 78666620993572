import { fadeAnimation } from './../../services/util/form-util.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MedicaoResponse } from 'src/app/services/operacoes/operacao/operacao.service';
import { runInThisContext } from 'vm';
import { CustomAlertService } from 'src/app/services/custom-alert/custom-alert.service';

@Component({
  selector: 'app-controle-medicao',
  templateUrl: './controle-medicao.component.html',
  styleUrls: ['./controle-medicao.component.scss'],
  animations:[fadeAnimation]
})
export class ControleMedicaoComponent implements OnInit {
  @ViewChild('input', { static: true }) input: ElementRef;

  title: string;
  tipo: string;
  operacao: any;
  lotes: any;
  loader: any;
  alert: any;
  operacoesService: any;
  loteSelecionado: number = 0;
  inputDataObj = {
    tov: "",
    volAgua: "",
    nov: "",
    tempProduto: "",
    densPadrao: "",
    grauApi: "",
    densCorregida: "",
    fcv: "",
    vef: "",
    litros: "",
    fcvBarril: "",
    barris: "",
    massa: "",
  }

  public modoAvancado: boolean = false;

  // formGroup: FormGroup
  // public validationMessages = {
  //   requiredText: [
  //     { type: 'required', message: 'This field is required.' }
  //   ],
  //   breveText: [
  //     { type: 'required', message: 'This field is required.' },
  //     { type: 'already_Exists', message: 'This field value already exists.' }
  //   ],
  // };
  public medicao: MedicaoResponse;

  constructor(
    private formBuilder: FormBuilder,
    // public loader: CustomLoaderService,
    // public alert: CustomAlertService,

    public dialogRef: MatDialogRef<ControleMedicaoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = data.title;
    this.tipo = data.tipo;
    this.operacao = data.operacao;
    this.loader = data.loader;
    this.alert = data.alert;
    this.operacoesService = data.operacoesService;

    if (this.tipo == "O") {
      this.lotes = JSON.parse(this.operacao.lotesVinculados);
    }
    else {
      this.lotes = JSON.parse(this.operacao.lotesVinculados)
    }
    // this.medicao.I.temperaturaProduto = this.medicao.I.temperaturaProduto.toString().replace(".",",");
    // this.medicao.F.temperaturaProduto = this.medicao.F.temperaturaProduto.toString().replace(".",",")
    // this.medicao.I.densidadePadrao = this.medicao.I.densidadePadrao.toString().replace(".",",");
    // this.medicao.F.densidadePadrao = this.medicao.F.densidadePadrao.toString().replace(".",",")
    // this.medicao.I.experienciaFatorNavio = this.medicao.I.experienciaFatorNavio.toString().replace(".",",");
    // this.medicao.F.experienciaFatorNavio = this.medicao.F.experienciaFatorNavio.toString().replace(".",",")
    // this.medicao.I.densidade20GC = this.medicao.I.densidade20GC.toString().replace(".",",");
    // this.medicao.F.densidade20GC = this.medicao.F.densidade20GC.toString().replace(".",",");
    // this.medicao.I.grauAPI60GF = this.medicao.I.grauAPI60GF.toString().replace(".",",");
    // this.medicao.F.grauAPI60GF = this.medicao.F.grauAPI60GF.toString().replace(".",",");
    // this.medicao.I.fatorCorrecaoVolumeLitros = this.medicao.I.fatorCorrecaoVolumeLitros.toString().replace(".",",");
    // this.medicao.F.fatorCorrecaoVolumeLitros = this.medicao.F.fatorCorrecaoVolumeLitros.toString().replace(".",",");
    // this.medicao.I.fatorCorrecaoVolumeBarril = this.medicao.I.fatorCorrecaoVolumeBarril.toString().replace(".",",");
    // this.medicao.F.fatorCorrecaoVolumeBarril = this.medicao.F.fatorCorrecaoVolumeBarril.toString().replace(".",",");
  }


  ngOnInit() {
    this.getLote(this.lotes[0])
  }

  getLote(lote) {
    this.operacoesService.getMedicao(this.operacao.id_operacao, this.tipo, lote.id_operacaoLote).then(res => {

      this.loteSelecionado = lote.id_operacaoLote;
      this.medicao = res;
      this.medicao.I.id_lote = lote.id_operacaoLote;
      this.medicao.F.id_lote = lote.id_operacaoLote;
      console.log(this.medicao)
      this.medicao.I.volumeLitrosTotal = this.validateValue(this.medicao.I.volumeLitrosTotal);
      this.medicao.F.volumeLitrosTotal = this.validateValue(this.medicao.F.volumeLitrosTotal);
      this.medicao.I.volumeLitrosAgua = this.validateValue(this.medicao.I.volumeLitrosAgua);
      this.medicao.F.volumeLitrosAgua = this.validateValue(this.medicao.F.volumeLitrosAgua);

      this.medicao.I.volumeLitrosLiquido = this.validateValue(this.medicao.I.volumeLitrosLiquido);
      this.medicao.F.volumeLitrosLiquido = this.validateValue(this.medicao.F.volumeLitrosLiquido);
      this.medicao.I.temperaturaProduto = this.validateValue(this.medicao.I.temperaturaProduto);
      this.medicao.F.temperaturaProduto = this.validateValue(this.medicao.F.temperaturaProduto);

      this.medicao.I.densidadePadrao = this.validateValue(this.medicao.I.densidadePadrao);
      this.medicao.F.densidadePadrao = this.validateValue(this.medicao.F.densidadePadrao);
      this.medicao.I.grauAPI60GF = this.validateValue(this.medicao.I.grauAPI60GF);
      this.medicao.F.grauAPI60GF = this.validateValue(this.medicao.F.grauAPI60GF);

      this.medicao.I.densidade20GC = this.validateValue(this.medicao.I.densidade20GC);
      this.medicao.F.densidade20GC = this.validateValue(this.medicao.F.densidade20GC);
      this.medicao.I.fatorCorrecaoVolumeLitros = this.validateValue(this.medicao.I.fatorCorrecaoVolumeLitros);
      this.medicao.F.fatorCorrecaoVolumeLitros = this.validateValue(this.medicao.F.fatorCorrecaoVolumeLitros);

      this.medicao.I.experienciaFatorNavio = this.validateValue(this.medicao.I.experienciaFatorNavio);
      this.medicao.F.experienciaFatorNavio = this.validateValue(this.medicao.F.experienciaFatorNavio);
      this.medicao.I.volumeLitros20GC = this.validateValue(this.medicao.I.volumeLitros20GC);
      this.medicao.F.volumeLitros20GC = this.validateValue(this.medicao.F.volumeLitros20GC);

      this.medicao.I.fatorCorrecaoVolumeBarril = this.validateValue(this.medicao.I.fatorCorrecaoVolumeBarril);
      this.medicao.F.fatorCorrecaoVolumeBarril = this.validateValue(this.medicao.F.fatorCorrecaoVolumeBarril);
      this.medicao.I.volumeBarril60GF = this.validateValue(this.medicao.I.volumeBarril60GF);
      this.medicao.F.volumeBarril60GF = this.validateValue(this.medicao.F.volumeBarril60GF);


      this.medicao.I.volumeKG = this.validateValue(this.medicao.I.volumeKG);
      this.medicao.F.volumeKG = this.validateValue(this.medicao.F.volumeKG);


      this.medicao.I.temperaturaProduto = this.validateValue(this.medicao.I.temperaturaProduto)
      this.medicao.F.temperaturaProduto = this.validateValue(this.medicao.F.temperaturaProduto)
      this.medicao.I.densidadePadrao = this.validateValue(this.medicao.I.densidadePadrao)
      this.medicao.F.densidadePadrao = this.validateValue(this.medicao.F.densidadePadrao)
      this.medicao.I.experienciaFatorNavio = this.validateValue(this.medicao.I.experienciaFatorNavio)
      this.medicao.F.experienciaFatorNavio = this.validateValue(this.medicao.F.experienciaFatorNavio)
      this.medicao.I.densidade20GC = this.validateValue(this.medicao.I.densidade20GC)
      this.medicao.F.densidade20GC = this.validateValue(this.medicao.F.densidade20GC)
      this.medicao.I.grauAPI60GF = this.validateValue(this.medicao.I.grauAPI60GF)
      this.medicao.F.grauAPI60GF = this.validateValue(this.medicao.F.grauAPI60GF)
      this.medicao.I.fatorCorrecaoVolumeLitros = this.validateValue(this.medicao.I.fatorCorrecaoVolumeLitros)
      this.medicao.F.fatorCorrecaoVolumeLitros = this.validateValue(this.medicao.F.fatorCorrecaoVolumeLitros)
      this.medicao.I.fatorCorrecaoVolumeBarril = this.validateValue(this.medicao.I.fatorCorrecaoVolumeBarril)
      this.medicao.F.fatorCorrecaoVolumeBarril = this.validateValue(this.medicao.F.fatorCorrecaoVolumeBarril)

      // //contas para preenchimentos de dados
      // this.medicao.I.volumeLitrosLiquido = this.contaSubtracao(this.medicao.I.volumeLitrosTotal, this.medicao.I.volumeLitrosAgua)
      // this.medicao.F.volumeLitrosLiquido = this.contaSubtracao(this.medicao.F.volumeLitrosTotal, this.medicao.F.volumeLitrosAgua)
      // this.medicao.I.densidade20GC = this.contaMultiplicacao(this.medicao.I.volumeLitrosLiquido, this.medicao.I.fatorCorrecaoVolumeLitros)
      // this.medicao.F.densidade20GC = this.contaMultiplicacao(this.medicao.F.volumeLitrosLiquido, this.medicao.F.fatorCorrecaoVolumeLitros)
      // this.medicao.I.volumeKG = this.contaDivisao(this.medicao.I.volumeLitrosLiquido, this.medicao.I.densidadePadrao)
      // this.medicao.F.volumeKG = this.contaDivisao(this.medicao.F.volumeLitrosLiquido, this.medicao.F.densidadePadrao)

    })
      .catch(err => {

        //console.log(err)
      })
  }

  contaSubtracao(value1, value2): number {
    if (value1 === null && value2 === null) {
      return null
    }
    else {
      return value1 - value2
    }
  }

  contaMultiplicacao(value1, value2): string {
    if (value1 === null && value2 === null) {
      return null
    }
    else {
      let value = value1 * value2
      return `${value}`
    }
  }

  contaDivisao(value1, value2): number {
    if (value1 === null && value2 === null) {
      return null
    }
    else {
      return value1 / value2

    }
  }

  validateValue(value, defaultValue: any = null) {
    if (value === "") {
      return defaultValue
    }
    else {
      return value ? value : defaultValue;
    }
  }

  calculaNov(tipo) {
    if (tipo === 'I') {
      if (this.medicao.I.volumeLitrosTotal < this.medicao.I.volumeLitrosAgua) {
        this.alert.overflowIconErrorAlert({
          title: "ALERT.ATENCAO",
          text: "TOV menor que Vol. água"
        });
        this.medicao.I.volumeLitrosAgua = 0
      }
      else {
        this.medicao.I.volumeLitrosLiquido = this.medicao.I.volumeLitrosTotal - this.medicao.I.volumeLitrosAgua
        this.calculaDensidade20GC(tipo);
        this.calculaMassa(tipo)
      }
    }
    else {
      if (this.medicao.F.volumeLitrosTotal < this.medicao.F.volumeLitrosAgua) {
        this.alert.overflowIconErrorAlert({
          title: "ALERT.ATENCAO",
          text: "TOV menor que Vol. água"
        });
        this.medicao.F.volumeLitrosAgua = 0
      }
      else {
        this.medicao.F.volumeLitrosLiquido = this.medicao.F.volumeLitrosTotal - this.medicao.F.volumeLitrosAgua
        this.calculaDensidade20GC(tipo);
        this.calculaMassa(tipo)
      }
    }
  }

  calculaDensidade20GC(tipo) {
    tipo === 'I' ? this.medicao.I.densidade20GC = this.medicao.I.volumeLitrosLiquido * this.medicao.I.fatorCorrecaoVolumeLitros : this.medicao.F.densidade20GC = this.medicao.F.volumeLitrosLiquido * this.medicao.F.fatorCorrecaoVolumeLitros
  }

  calculaMassa(tipo) {
    tipo === 'I' ? this.medicao.I.volumeKG = this.medicao.I.volumeLitrosLiquido / this.medicao.I.densidadePadrao : this.medicao.F.volumeKG = this.medicao.F.volumeLitrosLiquido * this.medicao.F.densidadePadrao
  }
}
